import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {MatRadioChange,MatCheckbox} from '@angular/material';
import { DataHelper } from '../DataHelper';
declare var $: any;
@Component({
  selector: 'app-SectorSelector',
  templateUrl: './SectorSelector.component.html',
  styleUrls: ['./SectorSelector.component.css']
})
export class SectorSelectorComponent implements OnInit {

  activeSector: string;
  
  allSectors: any[] = [
    {"label":"Poultry","color":"#006AFF"},
    {"label":"Cattle","color":"#8A5A42"},
    {"label":"Fruits and Vegetables","color":"#FF00FF"},
    {"label":"Press Mud","color":"#4FFF00"},
    {"label":"Pulp & Paper","color":"#FFFF00"},
    {"label":"Distillery","color":"#74B273"},
    {"label":"Urban Organic Liquid Waste","color":"#FF99CC"},
    {"label":"Slaughterhouse","color":"#FFEABF"},
    {"label":"Urban Organic Solid Waste","color":"#FFC95D"},
    {"label":"Combined","color":"#00FFFF"}
  ];

  allDistSectors: any[] = [
    {"label":"Poultry","color":"#006AFF"},
    {"label":"Cattle","color":"#8A5A42"},
    {"label":"Press Mud","color":"#4FFF00"},
    {"label":"Pulp & Paper","color":"#FFFF00"},
    {"label":"Distillery","color":"#74B273"},
    {"label":"Urban Organic Liquid Waste","color":"#FF99CC"},
    {"label":"Slaughterhouse","color":"#FFEABF"},
    {"label":"Urban Organic Solid Waste","color":"#FFC95D"},
    {"label":"Combined","color":"#00FFFF"}
  ];

  sectors: string[] = ['Poultry', 'Cattle', 'Fruits and Vegetables' , 'Press Mud', 'Pulp & Paper',
   'Distillery', 'Urban Organic Liquid Waste', 'Slaughterhouse', 'Urban Organic Solid Waste', 'Combined'];

  allPrioritySectors: any[] = [
    {"label":"MSW - Solid","color":"#800080"},
    {"label":"Cattle farm - Solid","color":"#FF00FF"},
    {"label":"F&V Raw - Solid","color":"#e699ff"},
    {"label":"Distillery - Liquid","color":"#910000"},
    {"label":"Poultry - Solid","color":"#0000FF"},
    {"label":"Sewage - Liquid","color":"#008080"},
    {"label":"Slaughter - Liquid","color":"#00FFFF"},
    {"label":"Paper - Liquid","color":"#008000"},
    {"label":"PressMud - Solid","color":"#00FF00"},
    {"label":"Meat - Liquid","color":"#808000"},
    {"label":"F&V Process - Solid","color":"#FFFF00"},
    {"label":"Others","color":"#343a40"},
    /*{"label":"OTHERS - Liquid","color":"#FF0000"},
    {"label":"OTHERS - Solid","color":"#808080"}*/
  ];

  @Input()
  activeLevel: string;

  @Output()
  sectorChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    this.activeSector = 'Combined';
  }

  ngOnChanges() { 
    if(this.activeLevel=="D"){
      //this.activeSector = 'Cattle';
      this.activeSector = 'Combined';
    }
    else{
      this.activeSector = 'Combined';
    }
  }

  sectorChange(event: MatRadioChange){ 
      // if(event.value=='Urban Organic Solid Waste'){        
      //   this.showModal();
      // }
      // else 
      
      {
        this.sectorChanged.emit(event.value);
      }
      
  }

  
  checkBoxsectorChange(event: MatCheckbox){
    if(event.checked){
      this.sectorChanged.emit('Combined');
    }    
  }

  getSectorState(sector: string) : boolean
  {
    return false;
  }


  showModal():void {     
      $("#sectorSelectedModal").modal({
        backdrop: 'static',
        keyboard: false

      }); 
  }

  hideModal():void {
    document.getElementById('close-modal-sector').click();
 }

}



