import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule,APP_INITIALIZER  } from '@angular/core';
import { MatRadioModule, MatInputModule, MatTableModule} from '@angular/material';
import { AppComponent } from './app.component';
import { LeafletMapComponent } from './LeafletMap/LeafletMap.component';
import { DataChartComponent } from './DataChart/DataChart.component';
import { SectorSelectorComponent } from './SectorSelector/SectorSelector.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataComponent } from './data/data.component';

import { HttpClientModule,HttpClient } from '@angular/common/http';
import {MatSelectModule} from '@angular/material/select';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import {NgPrintModule} from 'ng-print';
import {NgxPrintModule} from 'ngx-print';


/* Material Design */
import {MatCheckboxModule} from '@angular/material';
import {MatButtonModule} from '@angular/material';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';

// import cookies
import{CookieService} from 'ngx-cookie-service';
import { StatesDataComponent } from './states-data/states-data.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
/*
export function initApp(http: HttpClient) {
   return () => {
     return http.get('https://api.github.com/users/sagar-ganatra')
       .toPromise()
       .then((resp) => {
         console.log('Response 1 - ', resp);
       });
   };
}
*/

import { AppLoadService } from './app-load.service';

export function init_app(appLoadService: AppLoadService) {
    return () => appLoadService.initializeApp();
}
 

/*
export function get_settings(appLoadService: AppLoadService) {
    return () => appLoadService.getSettings();
}
*/

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DialogDataComponent } from './DialogData/DialogData.component';



@NgModule({
   declarations: [
      AppComponent,
      LeafletMapComponent,
      DataChartComponent,
      SectorSelectorComponent,
      DataComponent,
      StatesDataComponent,
      DialogDataComponent,
   ],
   imports: [
      BrowserModule,
      FormsModule,
      MatRadioModule,
      MatInputModule,
      MatTableModule,
      BrowserAnimationsModule,
      HttpClientModule,
      MatSelectModule,
      Ng2GoogleChartsModule,
      NgPrintModule,
      MatCheckboxModule,
      MatButtonModule,
      MatInputModule,
      MatAutocompleteModule,
      MatDatepickerModule,
      MatFormFieldModule,
      MatRadioModule,
      MatSelectModule,
      MatSliderModule,
      MatSlideToggleModule,
      MatMenuModule,
      MatSidenavModule,
      MatToolbarModule,
      MatListModule,
      MatGridListModule,
      MatCardModule,
      MatStepperModule,
      MatTabsModule,
      MatExpansionModule,
      MatButtonToggleModule,
      MatChipsModule,
      MatIconModule,
      MatProgressSpinnerModule,
      MatProgressBarModule,
      MatDialogModule,
      MatTooltipModule,
      MatSnackBarModule,
      MatTableModule,
      MatSortModule,
      MatPaginatorModule,
      NgbModule,
      DragDropModule,
      NgxPrintModule
   ],
   entryComponents: [DialogDataComponent],
   /*
   providers: [{
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [HttpClient]
     }
   ],
   */

   providers: [
      AppLoadService,
      { provide: APP_INITIALIZER, useFactory: init_app, deps: [AppLoadService], multi: true },
      /*{ provide: APP_INITIALIZER, useFactory: get_settings, deps: [AppLoadService], multi: true },*/
      /*{
         provide: APP_INITIALIZER,
         useFactory: initApp,
         multi: true,
         deps: [HttpClient]
       }*/
   ],
   
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
