import { Component, OnInit ,Injectable,Inject } from '@angular/core';
import { DataHelper } from './DataHelper';

import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material';

import { DialogDataComponent } from './DialogData/DialogData.component';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';

const FileSaver = require('file-saver');
declare var $: any;

@Injectable({
  providedIn: 'root'
})


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent{
  public activeSector: any;
  public activeEPData: any;
  public selectedLevel: string; 
  public activeDistrict: string;
  public dialogOpen:Number= 0; 
  public checkModel: any = { left: true, middle: false, right: false };
  public radioModel: string = 'Left';
  
  public resetTable:boolean; 
  ngOnInit()
  {   
    this.activeSector = "Overall";
    this.selectedLevel="C"; 
    //this.setCookie('isModelOpen', '', -1);
    //let isModelOpen = this.getCookie('isModelOpen');    
    this.showModal();  
    //this.showBlinkingModal();  
  }

  constructor(private dialog: MatDialog)
  {    
    this.activeSector = "Combined";
    this.selectedLevel="C";    
  }

  public onSectorChanged(sector: any)
  { 
	if (sector != null)
    this.activeSector = sector;
  }

  public levelChanged(item)
  {
    this.activeEPData = null;
    if (this.selectedLevel === 'C')
    {      
      this.activeSector = 'Overall';
    }
    else if (this.selectedLevel === 'D'){      
      //this.activeSector = 'Cattle';
      this.activeSector = 'Combined';
    }
    else{
      this.activeSector = 'Combined';
    }
  }

  public onMapSelectionChanged(epData: any)
  { 
    this.resetTable=false;
	  if (epData != null){
      this.activeEPData = epData[0];
    }
    
    if (this.selectedLevel === 'D')
    {
      this.activeDistrict = epData[1];
    }
  }

  public viewDistrictData(activeLevel)
  { 
	  this.selectedLevel = activeLevel; 
  }
  
  public onResetMap(selectedLevel: any)
  {   
        
      this.resetTable=false;
      this.selectedLevel=selectedLevel; 
      this.activeEPData = null;    
      this.activeDistrict = null;
      
      if (selectedLevel === 'C')
      {     
        this.activeSector = 'Overall';
      }
      else {  
        this.resetTable=true; 
      }
  }

  openAlertDialog(message: string) { 
    
    this.dialogOpen=1;

    const dialogRef = this.dialog.open(DialogDataComponent,{     
      panelClass: 'my-dialog',
      data:{
        selectedLevel: this.selectedLevel ,
        activeSector:this.activeSector,
        activeEPData:this.activeEPData,
        activeDistrict:this.activeDistrict,
        dialogOpen:this.dialogOpen
      },
      autoFocus: true,
      disableClose: true,
    });
  }

  //Modal and cookies to display and hide model  

  showBlinkingModal():void {     
    $("#blinkModal").modal({
       backdrop: 'static',
       keyboard: false

    }); 
    this.setCookie('isModelOpen', '1', 1);
  }
  
  hideBlinkModal():void {  
    $('#blinkModal').modal('hide');
 }

  showModal():void {     
     $("#myModal").modal({
        backdrop: 'static',
        keyboard: false

     }); 
     this.setCookie('isModelOpen', '1', 1);
  }
  sendModal(): void {     
     this.hideModal();
  }
  hideModal():void {
     document.getElementById('close-modal').click();
  }

  private getCookie(name: string) {
      let ca: Array<string> = document.cookie.split(';');
      let caLen: number = ca.length;
      let cookieName = `${name}=`;
      let c: string;

      for (let i: number = 0; i < caLen; i += 1) {
          c = ca[i].replace(/^\s+/g, '');
          if (c.indexOf(cookieName) == 0) {
              return c.substring(cookieName.length, c.length);
          }
      }
      return '';
  }

  private deleteCookie(name) {
    this.setCookie(name, '', -1);
  }

  private setCookie(name: string, value: string, expireDays: number, path: string = '') {
      let d:Date = new Date();
      d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000); 
      //d.setTime(d.getTime() + expireDays * 60 * 1000); For 1 minute

      let expires:string = `expires=${d.toUTCString()}`;
      let cpath:string = path ? `; path=${path}` : '';
      document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

  //End Modal and cookies to display and hide model

  /*
  receivedChildMessage: string;
  getMessage(message: string) {
    this.dialogOpen=1;
    const dialogRef = this.dialog.open(DialogDataComponent,{
      data:{
        selectedLevel: this.selectedLevel ,
        activeSector:this.activeSector,
        activeEPData:this.activeEPData,
        dialogOpen:this.dialogOpen
      },
    });
  }
  */

  showDownloadModal():void {     
      $("#downloadModal").modal({
        backdrop: 'static',
        keyboard: false
    
      });
      $('#blinkModal').modal('hide'); 
	  }
	
	  hideDownloadModal():void {  //document.getElementById('close-modal-sector').click();
	 
		   $('#downloadModal').modal('hide');
	  }


    downloadPdf(pdfUrl: string, pdfName: string ) {
      //console.log(pdfUrl);      
      FileSaver.saveAs(pdfUrl, pdfName);
    }

}
