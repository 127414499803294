import { Component, OnInit, AfterViewInit,Input,Output, OnChanges, SimpleChange ,EventEmitter} from '@angular/core';
import { ChartReadyEvent, ChartErrorEvent, ChartSelectEvent,
	ChartMouseOverEvent, ChartMouseOutEvent } from 'ng2-google-charts';
 import { GoogleChartInterface } from 'ng2-google-charts/google-charts-interfaces';
 import {MatRadioChange,MatCheckbox} from '@angular/material';

 import { DataHelper } from '../DataHelper';
import { IfStmt } from '@angular/compiler';
import { FooterRowOutlet } from '@angular/cdk/table';
 declare var google: any;
declare var $: any;
declare var google: any;



@Component({
  selector: 'app-DataChart',
  templateUrl: './DataChart.component.html',
  styleUrls: ['./DataChart.component.css']
})
export class DataChartComponent implements OnInit {
  
  constructor() { 

  }  
  
  public imageURI:any;
  selectedChart:any;
  title:string;
  ePChartVisualization:any;
  wPChartVisualization:any;
  chartTempData:any;
  stateName:string;
  ePtitle:string;
  wGtitle:string;

  wasteType:any;
  solidWaste:any;
  liquidWaste:any;

  public tempAray:any=[];
  @Input() activeSector: string;
	@Input() activeEPData: Map<string, any>;
	@Input() activeLevel: string;
  @Input() activeDistrict: string;

  public chartFontSize:any;
  public chartHeight:any;
  ngOnInit() { 
    this.title="PieChart";
  }
    
  ngOnChanges() {
    this.checkWasteType();
    this.updateChartData();    
  }

  public checkWasteType(){
      if(this.activeLevel == 'S' ||  this.activeLevel == 'D'){
         this.solidWaste= false;
         this.liquidWaste= false;         
         if(this.activeSector=='Pulp & Paper' || this.activeSector=='Distillery' || this.activeSector=='Urban Organic Liquid Waste'){
            this.wasteType="Liquid Waste";
            this.liquidWaste= true;
         } 
         else {
            this.wasteType="Solid Waste";             
            this.solidWaste= true;
         }     
      }
  }
  
  public energyPotentialChart: GoogleChartInterface= {
    chartType: 'PieChart',
    options: {     
      //chartArea:{'width': '90%','height': '78%'},
      is3D:true,
      //pieHole: 0.5,
      //pieSliceText: 'value-and-percentage',
      pieSliceText: 'none',
      //sliceVisibilityThreshold :0,
      fontSize: 8,
      legend: {
        position: 'labeled',
        alignment:'end',
        textStyle:{fontSize:8}
      },
      pieSliceTextStyle: {
        //color: 'black'
      },
      sliceVisibilityThreshold: 0
      //pieSliceBorderColor:'red'	

    }
  };
  
  public wasteGenerationChart: GoogleChartInterface= {
    chartType: 'PieChart',
    options: {       
      //chartArea:{'width': '90%','height': '78%'},
      is3D:true,
      //pieHole: 0.5,
      //pieSliceText: 'label-and-percentage',
      //pieSliceText: 'value-and-percentage',
      pieSliceText: 'percentage',
      sliceVisibilityThreshold :0,
      fontSize: 8,
      legend: {
        position: 'labeled',
        alignment:'center',
        textStyle:{fontSize:8}
      }	,
      pieSliceTextStyle: {
        //color: 'black'
      }

    }
  };

 /*
  public wasteGenerationChart: GoogleChartInterface= {
    chartType: 'PieChart',
    options: {      
      chartArea:{'width': '90%','height': '78%'},
      is3D:true,
      pieHole: 0.5,
      pieSliceText: 'label-and-percentage',
      sliceVisibilityThreshold :0,
      fontSize: 11,
      legend: {
        position: 'labeled',
      },	

    }
  };
  */

  public updateChartData(){
    var districtChart = false;
    if (this.activeLevel == 'D' && this.activeDistrict != null)
    {
      districtChart = true;
    }

    this.ePtitle ="Sectoral Share in Energy Potential";
    this.wGtitle ="Sectoral Share in Waste Generation";
    this.stateName=null;

    if(this.activeEPData) {
      this.stateName = this.activeEPData.get('STATE_UT');
      if (!this.stateName)
      {
        this.stateName = this.activeEPData.get('State');
      }
    }
    this.energyPotentialChart = Object.create(this.energyPotentialChart);
    var epTempChartData:any;
    if (this.activeLevel === 'C') {
      epTempChartData = DataHelper.energyPotentialChartData(this.stateName); 
      this.chartFontSize=8;
    }
    else {
      epTempChartData = DataHelper.getPrioritySectorsChartData(this.stateName, 'ENERGY POTENTIAL',this.activeLevel,this.activeSector, this.wasteType, this.activeDistrict);
      this.chartFontSize=8;
    }
    
    //console.log( epTempChartData[0]);
    //console.log(epTempChartData[0]);
     

    this.energyPotentialChart.dataTable= this.filterData(epTempChartData[0]);      
    this.energyPotentialChart.options['title'] = epTempChartData[0][0][1];  
    this.energyPotentialChart.options['colors'] = epTempChartData[1];
        
    this.energyPotentialChart.options['slices']=null;
    if(epTempChartData[2] && this.activeLevel !== 'C'){
      this.energyPotentialChart.options['slices']= this.getChartLegendStatus(epTempChartData[2]);
      if(this.activeSector !=='Combined'){
        //console.log(epTempChartData[2]);
        this.energyPotentialChart.options['colors'] = epTempChartData[1] 
      }

    }
    
    this.energyPotentialChart.options['chartArea'] = {'width': '90%','height': '78%'};
    this.energyPotentialChart.options['height'] = '100%';

    this.energyPotentialChart.options['pieSliceText'] = 'percentage';

    if(window.innerWidth > 1380 || window.innerWidth < 768){
      this.energyPotentialChart.options['height'] = 250;
      this.energyPotentialChart.options['chartArea'] = {top:20,left:5, right:5, width:"100%",height:"230"};
    }     
    if(window.innerWidth < 1380 && window.innerWidth >769 ){
      this.energyPotentialChart.options['height'] = 230;
      this.energyPotentialChart.options['chartArea'] = {top:20,left:5, right:5, width:"100%",height:"200"};
    }    
    if(this.activeLevel === 'C') {     
      this.energyPotentialChart.options['pieSliceText'] = 'percentage';
       
    }
    
    
    this.energyPotentialChart.options['legend'] = {
      position: 'labeled',
      alignment:'center',
      textStyle:{fontSize:this.chartFontSize},
    }	;
        
    this.ePChartVisualization= this.energyPotentialChart;

    this.wasteGenerationChart = Object.create(this.wasteGenerationChart);
    var wgTempChartData:any;
    if (this.activeLevel === 'C') {
      wgTempChartData = DataHelper.wasteGenerationChartData(this.stateName); 
    }
    else {
      wgTempChartData = DataHelper.getPrioritySectorsChartData(this.stateName, 'WASTE GENERATION',this.activeLevel,this.activeSector,this.wasteType, this.activeDistrict);
            
    }

    this.wasteGenerationChart.dataTable= wgTempChartData[0]; 
    this.wasteGenerationChart.options['colors'] = wgTempChartData[1];
    
    this.wasteGenerationChart.options['title'] = wgTempChartData[0][0][1]; 
    this.wasteGenerationChart.options['legend'] = {
      position: 'labeled',
      alignment:'center',
      textStyle:{fontSize:this.chartFontSize}
    }	;

    this.wasteGenerationChart.options['chartArea'] = {'width': '90%','height': '78%'};
    this.wasteGenerationChart.options['height'] = '100%';

    if(window.innerWidth > 1380 || window.innerWidth < 768){
      this.wasteGenerationChart.options['height'] = 250;
      this.wasteGenerationChart.options['chartArea'] = {top:20,left:5, right:5, width:"100%",height:"230"};
    }     
    if(window.innerWidth < 1380 && window.innerWidth >769 ){
      this.wasteGenerationChart.options['height'] = 230;
      this.wasteGenerationChart.options['chartArea'] = {top:20,left:5, right:5, width:"100%",height:"200"};
    }     

    this.wasteGenerationChart.options['slices']=null;
    if(wgTempChartData[2] && this.activeLevel !== 'C') {
      this.wasteGenerationChart.options['slices']= this.getChartLegendStatus(wgTempChartData[2]);
    }

    this.wPChartVisualization= this.wasteGenerationChart; 
  }
   

	public charExport(chrt){
      this.imageURI="";
      if(chrt=="EPChart"){
        this.imageURI = this.ePChartVisualization.component.wrapper.getChart().getImageURI();
      }
      if(chrt=="WGChart"){
        this.imageURI = this.wPChartVisualization.component.wrapper.getChart().getImageURI();
      }		
  }


  public getChartLegendStatus(epTempChartData){
      var legendVal :any= epTempChartData;
      var legendObject:any=[];
      if(legendVal) {
          legendVal.forEach((value: any, key: any) => { 
              //legendObject.push({visibleInLegend: value});
              var offset=0;
              //var color= 'transparent';
              var color= 'white';
              var opacity= 1;
              if(value){
                  offset= 0.1;
                  color='black';
                  opacity=1;
              }
              //legendObject.push({visibleInLegend: value, offset: offset, textStyle: { color: color }});
              legendObject.push({offset: offset, textStyle: { color: color, opacity: opacity }});
          });
      }
      return legendObject;
  }

  
  changeWasteType(event: MatRadioChange){     
    this.wasteType = event.value;
    if(event.value=='Liquid Waste'){
      this.solidWaste= false;
      this.liquidWaste= true; 
    }
    if(event.value=='Solid Waste'){
      this.liquidWaste= false; 
      this.solidWaste= true;      
    }  
    this.updateChartData();
  }
  
  filterData(sectorDAta){
      var chartData:any=[];
      for (let i = 0; i < sectorDAta.length; i++) { 
        var chartData2:any=[];
          for(let j=0; j<=1; j++){
                let rowVal= sectorDAta[i][j];
                //chartData2.push(sectorDAta[i][j]);             
                if(rowVal=='Fruits and Vegetables'){            
                  chartData2.push('Fruits and Vegetables');
                }
                else if(rowVal=='Urban Organic Liquid Waste'){              
                  chartData2.push('Urban Organic Liquid Waste');
                }            
                else if(rowVal=='Urban Organic Solid Waste'){           
                  chartData2.push('Urban Organic Solid Waste');
                }
                else if(rowVal=='Slaughterhouse'){           
                  chartData2.push('Slaughterhouse');
                }
                else if(rowVal=='Pulp & Paper'){           
                  chartData2.push('Pulp & Paper');
                }
                else if(rowVal=='Press Mud'){           
                  chartData2.push('Press Mud');
                }
                
                else{
                    chartData2.push(rowVal);
                }          
          }
          chartData.push(chartData2);
          

      }      
      return chartData;    
  }

  /*
  fadeColor(colors, epTempChartData){
    var tempColor:any=[];
    for (let i = 0; i < epTempChartData.length; i++) { 
         if(epTempChartData[i]==false){
               tempColor.push(colors[i]);
         }
         else {
          tempColor.push(colors[i]);
         }
         
    }
    return tempColor;
  }
  */
  /*
  stateList:any=[1,2];
  drawChart = () => { 
       
    for (let i = 0; i < this.stateList.length; i++) { 
        let data = google.visualization.arrayToDataTable([
          ['Task', 'Hours per Day'],
          ['Work', 11],
          ['Eat', 2],
          ['Commute', 2],
          ['Watch TV', 2],
          ['Sleep', 7]
        ]);
      
        let options = {
          height:80,
          width:80,
          chartArea:{'width': '100%', 'height': '100%'},
          legend: {position: 'none'}
        };
      
        let chart = new google.visualization.PieChart(document.getElementById('stateChartList'+this.stateList[i]));      
        chart.draw(data, options); 
        this.tempAray.push(chart.getImageURI());

        google.charts.load('current', { 'packages': ['corechart'] });    
        google.charts.setOnLoadCallback(this.drawChart);    
        console.log(this.tempAray);
    }
  }
  */
      
}


