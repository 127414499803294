import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataHelper } from './DataHelper';

@Injectable({
  providedIn: 'root'
})
export class AppLoadService {
  
  constructor(private httpClient: HttpClient) { }

  initializeApp(): Promise<any> {
    return new Promise((resolve, reject) => {
          console.log(`initializeApp:: inside promise`);
          DataHelper.loadCountryData('/assets/LAYER 1_CONSOLIDATED.xlsx');
          setTimeout(() => {
            console.log(`initializeApp:: inside setTimeout`);            
            // doing something

            resolve();
          }, 5000);
        });
  }

 
  /*getSettings(): Promise<any> {
    console.log(`getSettings:: before http.get call`);
    
    const promise = this.httpClient.get('http://test')
      .toPromise()
      .then(settings => {
          
          return settings;
      });

    return promise;
  } */
  

}
