import { Component, AfterViewInit, Input, Output,OnChanges, SimpleChange,ChangeDetectorRef,EventEmitter,ElementRef,ViewChild } from '@angular/core';
import { DataHelper } from '../DataHelper';

import { MatTableDataSource } from '@angular/material/table';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import * as XLSX from "xlsx";

import * as jspdf from 'jspdf';  
import html2canvas from 'html2canvas';
declare var $: any;

@Component({
  selector: 'app-states-data',
  templateUrl: './states-data.component.html',
  styleUrls: ['./states-data.component.css']
})
export class StatesDataComponent implements AfterViewInit {
  
	@Input() activeSector: string;
	@Input() activeEPData: Map<string, any>;
	@Input() activeLevel: string;
	@Input() dialogOpen: Number;
	
	tableTitle:string;   
	tableHeaders:string[];
	countryData:string[][];
	statesData:any;

	data: object[] = []; 
	dataSource:any; 
	Divheight:any;
	stateName:string;
    imageURI:string;
	tableTitleWidth:any=89;
	@Output() openAlertDialogToEmit = new EventEmitter<string>();


	ngAfterViewInit() { 			
		this.tableHeaders = DataHelper.getCountryOverallDataHeaders();
		this.countryData = DataHelper.getCountryOverallData();         
	}
    
	constructor() {        
		
	}   
   
	openAlertDialog(message: string) {
		this.openAlertDialogToEmit.emit(message)
	}

   displayedColumns:any;
   customDataTable:string ;
   stateSelected:any ;
   

	ngOnChanges() { 
		if(this.dialogOpen){
			setTimeout( ()=>{
				this.captureImage();
			}, 100)
			this.Divheight= 65;
			this.tableTitleWidth=80;
		}
		// console.log('after click on india btn');
		//var stateName = '';
		//alert(this.dialogOpen);
		//console.log(this.activeEPData);
        if(this.activeEPData) {
			this.stateName = this.activeEPData.get('State');
			this.tableHeaders = DataHelper.getStateOverallDataHeaders(this.stateName);
			this.countryData = DataHelper.getStateOverallData(this.stateName);
			this.tableTitle = DataHelper.getStateOverallDataTitle(this.stateName); 
			//console.log(this.cHead);
			this.stateSelected=1;
			this.generateStateTable();

		}
		else
		{
			
			this.stateSelected=0;
			this.tableHeaders = DataHelper.getCountryOverallDataHeaders();
			this.countryData = DataHelper.getCountryOverallData();
			this.tableTitle = DataHelper.getCountryOverallDataTitle(); 
			
			this.data =[];
			for (var i = 0; i < this.countryData.length; i++){	
				let pusheditems = {};
				var j=0;
				for (var headVal of this.tableHeaders) {
					pusheditems[headVal] = this.countryData[i][j];
					j++;
				}
				this.data.push(pusheditems); 
				
			}
			this.displayedColumns= this.tableHeaders;
			this.dataSource = new MatTableDataSource(this.data);
			
			//document.getElementById('customTbl').innerHTML = "";
		}
		
	}
	
	generateStateTable(){
		var tblHeader ="";
		var tblTH="";
		
		for (var headVal of this.tableHeaders) {
			if (headVal.includes('Sector') || headVal.includes('Waste Type')){ 	
				if (headVal.includes('Sector')){
					tblTH+= "<th class='' colspan='2'>"+headVal+"</th>";
				}
				else{
					tblTH+= "";
				}
			}
			else{
				tblTH+= "<th class=''>"+headVal+"</th>";
			}
		}
        
		var tblRow ="";
		var tblBody="";

		for (var row = 0; row < this.countryData.length; row++){				
			var childrenHtml="";
			
			for (var col = 0; col < this.tableHeaders.length; col++) { 
				let colVal= this.countryData[row][col];	
				if (row === this.countryData.length - 4 || row === this.countryData.length - 2)
				{
					if(col === 0 || col >= this.tableHeaders.length - 2){
						childrenHtml+= "<td class='mat-cell' rowspan='2'>"+ colVal+"</td>";
					}
					else{
						childrenHtml+= "<td class='mat-cell'>"+ colVal+"</td>";
					}

				}
				else if (row === this.countryData.length - 3 || row === this.countryData.length - 1)
				{
					if(col > 0 && col < this.tableHeaders.length - 2)
					{
						childrenHtml+= "<td class='mat-cell'>"+ colVal+"</td>";
					}
				}
				else{
					childrenHtml+= "<td class='mat-cell'>"+ colVal+"</td>";
				}	
				
			}
			tblRow +="<tr class='mat-row'>"+childrenHtml+"</tr>";
			
		}
		
		tblHeader= "<thead><tr class='mat-header-cell' >"+tblTH+"</tr></thead>";
		tblBody="<tbody>"+tblRow+"</tbody>";

		var tblData = tblHeader+tblBody;
		this.customDataTable="<table>"+tblHeader+tblBody+"</table>";
		//document.getElementById('customTbl').innerHTML = this.customDataTable;
		//return tblData;
	}
	
	public captureScreen()  
	{  
		var data = document.getElementById('captureTable');  
		html2canvas(data).then(canvas => {  
		// Few necessary setting options  
		var imgWidth = 208;   
		var pageHeight = 295;    
		var imgHeight = canvas.height * imgWidth / canvas.width;  
		var heightLeft = imgHeight;  
	
		const contentDataURL = canvas.toDataURL('image/png')  
		let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
		var position = 0;  
		pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight) 
		pdf.save('DataTable.pdf'); // Generated PDF   
		});  
	}

	print = () => {
		let doc = new jsPDF(); 
        doc.setFontSize(11);
		var finalY = doc.lastAutoTable.finalY || 10;

        var width = doc.internal.pageSize.getWidth();
        //doc.text('Centered text', width/2, 20, { align: 'center' })

         doc.text(this.tableTitle, width/2, finalY + 15,{ align: 'center' });

		if(this.stateSelected ===0){
			doc.autoTable({
				startY: finalY + 20,
				head: [this.tableHeaders],
				body: this.countryData,
				styles: { cellPadding: 0.5, fontSize: 8 }
			});
		}
		if(this.stateSelected !==0){
			doc.autoTable({
				startY: finalY + 20,
				html: '#DataTable',				
                styles: { cellPadding: 0.5, fontSize: 8 },
			});
		}
		doc.save('DataTable.pdf')
	}
	
	public exportToExcel(tableId: string, name?: string) { 
		let timeSpan = new Date().toISOString();
		let prefix = name || "ExportResult";
		let fileName = 'Data';
		let targetTableElm = document.getElementById(tableId);		
		let wb = XLSX.utils.table_to_book(targetTableElm, <XLSX.Table2SheetOpts>{ sheet: prefix });
		XLSX.writeFile(wb, `${fileName}.xlsx`);
	}

	exportTable(){ 
		this.exportToExcel("DataTable");
   }

   exportExcel() {
    const workSheet = XLSX.utils.json_to_sheet(this.data, {header:this.tableHeaders});
		const workBook: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workBook, workSheet, 'SheetName');
		XLSX.writeFile(workBook, 'DataTable.xlsx');
	}

	roundDecimalPoint(num){
		return DataHelper.roundDecimalPoint(num);
	}

	public captureImage()  
	{  
		
		if(this.activeEPData){
			var data = document.getElementById('DataTable');
		}
		else {
			var data = document.getElementById('captureTable');
		}		

		html2canvas(data).then(canvas => {  
			const contentDataURL = canvas.toDataURL('image/png') 
		    //const contentDataURL = canvas.toDataURL('image/jpeg', 1.0); 
		    this.imageURI=contentDataURL;
		 
		});  
	} 

    showModal():void {     
		$("#popContentSource").modal({
		  backdrop: 'static',
		  keyboard: false
  
		}); 
	}
  
	hideModal():void {
	 
	  $('#popContentSource').modal('hide');
   }

   showModal2():void {     
	$("#popContent").modal({
	  backdrop: 'static',
	  keyboard: false

	}); 
  }

  hideModal2():void {  //document.getElementById('close-modal-sector').click();
 
    $('#popContent').modal('hide');
  }
  




}
