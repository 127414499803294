
import { Component, OnInit, Inject, AfterViewInit, Input, Output,OnChanges, SimpleChange,ChangeDetectorRef,EventEmitter } from '@angular/core';
import { DataHelper } from '../DataHelper';

import { MatTableDataSource } from '@angular/material/table';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import * as XLSX from "xlsx";


import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-data',
  templateUrl: './DialogData.component.html',
  styleUrls: ['./DialogData.component.css']
})
export class DialogDataComponent implements AfterViewInit {

  @Input() activeSector: string;
	@Input() activeEPData: Map<string, any>;
	@Input() activeLevel: string;
	@Input() dialogOpen: Number;
	
	@Input() activeDistrict: string;

	tableTitle:string;   
	tableHeaders:string[];
	
	epProps : string[][]; 
	epHeaders: string[];

	statesData:any;

	data: object[] = []; 
	dataSource:any; 
	Divheight:any;
	
	@Output() openAlertDialogToEmit = new EventEmitter<string>();


	ngAfterViewInit() { 			
		        
	}
   
	openAlertDialog(message: string) {
		this.openAlertDialogToEmit.emit(message)
	}

   displayedColumns:any;
   customDataTable:string ;
   stateSelected:any ;
  public selectedLevel: string; 

  ngOnInit() {

  }
   
   
  constructor(
    @Inject(MAT_DIALOG_DATA) private dataTemp: any,
    private dialogRef: MatDialogRef<DialogDataComponent>) {
    if (dataTemp) {
      this.selectedLevel = dataTemp.selectedLevel ;
      this.activeSector = dataTemp.activeSector ;
	  this.activeEPData = dataTemp.activeEPData ;

	  this.activeDistrict = dataTemp.activeDistrict ;

	  //console.log(this.activeEPData);
	  
      if(this.activeEPData){
        this.stateSelected=1;
      }
      else{
		
			this.stateSelected=0;
			// if(this.selectedLevel==="S"){
      //          this.StateOverallData();
		  //   }        
      }

      this.dialogOpen= dataTemp.dialogOpen;
    }
    this.dialogRef.updateSize('100vh','100vh');
  }

  onConfirmClick(): void {
     this.dialogRef.close(true);
     //this.dialogRef.close(true);
     //this.Divheight=null;
  }
  
  StateOverallData() { 
	    this.Divheight= 65;
		
		this.stateSelected=0;

		//this.tableHeaders = DataHelper.getCountryOverallDataHeaders();
		//this.countryData = DataHelper.getCountryOverallData();

		/*
		this.tableHeaders = DataHelper.getCountryOverallDataHeaders();
		this.countryData = DataHelper.getCountryOverallData();		
        console.log(this.countryData);
		*/
		this.epHeaders=["STATE_UT","SUGAR FACTORY","POULTRY","CATTLE","FRUITS & VEGETABLE",		                   
                          "WASTE GENERATION","ENERGY POTENTIAL","BIO-CNG POTENTIAL"	                       
	                      ];

        this.tableTitle = DataHelper.getCountryOverallDataTitle(); 

		this.epProps = [
			 ["Andhra Pradeshtra", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Arunachal Prades ", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Assam", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Bihar", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Chhattisgarh", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Goa", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Gujarat", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Haryana", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Himachal Pradesh", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Chhattisgarh", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Goa", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Gujarat", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Haryana", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Himachal Pradesh", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Chhattisgarh", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Goa", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Gujarat", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Haryana", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Himachal Pradesh", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Gujarat", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Haryana", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Himachal Pradesh", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Chhattisgarh", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Goa", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Gujarat", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Haryana", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"],
			 ["Himachal Pradesh", "62", "1,535", "1,195", "5,736","5,736","5,736","5,736"]
			 
		];
				
        this.data =[];       
		
			for (var i = 0; i < this.epProps.length; i++){	
				let pusheditems = {};
				var j=0;
				for (var headVal of this.epHeaders) {
					pusheditems[headVal] = this.epProps[i][j];
					j++;
				}
				this.data.push(pusheditems); 
				
			}
				
			this.displayedColumns= this.epHeaders;
			this.dataSource = new MatTableDataSource(this.data);
			
	}
	
    print = () => {
		let doc = new jsPDF(); 
		doc.autoTable({
		  head: [this.displayedColumns],
		  body: this.epProps
		});
		doc.save('DataTable.pdf')
	}

	exportExcel() {
		const workSheet = XLSX.utils.json_to_sheet(this.data, {header:this.epHeaders});
			const workBook: XLSX.WorkBook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workBook, workSheet, 'SheetName');
			XLSX.writeFile(workBook, 'DataTable.xlsx');
	}

}
