import { Component, OnInit, Input,Output, OnChanges, SimpleChange ,EventEmitter,ElementRef ,ViewChild} from '@angular/core';
import { DataHelper } from '../DataHelper';
import { MatTableDataSource } from '@angular/material/table';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from "xlsx";
import { InvokeFunctionExpr } from '@angular/compiler';

import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';

import * as jspdf from 'jspdf';  
import html2canvas from 'html2canvas';
declare var $: any;

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.css']
})
export class DataComponent implements OnInit {
	@Input() activeSector: string;
	@Input() activeEPData: Map<string, any>;
	@Input() activeDistrict: string;
	@Input() activeLevel: string;
	@Input() resetTable:boolean; 
	@Input() dialogOpen: Number;

	@Output()
	viewDistrictDataEvent: EventEmitter<any> = new EventEmitter<any>();
	@Output() openAlertDialogToEmit = new EventEmitter<string>();
	
	ngOnInit() {
		
	}
  
  tableTitle:string; 
  stateName:string;
  districtDataAvailable:boolean;
  public epHeaders: string[]; 
  epProps : string[][];  
  imageURI:any;
  disableDisticBtn:boolean;

  tableTitleWidth:any=89;

	constructor() {        
        
	}    

	tableHdr:any;
	data: object[] = []; 
	dataSource:any; 
	displayedColumns:any;
	fData:any;
	distHasData:any=true;
	Divheight:any;
	
	openAlertDialog(message: string) {
		this.openAlertDialogToEmit.emit(message)
	}

	ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
		if(this.dialogOpen){			
			setTimeout( ()=>{
				this.captureImage();
			}, 200)
			this.Divheight= 65;
			this.tableTitleWidth=80;
		}

		this.data =[]; 
		var updateTable:Boolean = false;	   
		for (let propName in changes) {
		  if (propName == null)
			continue;
		  let changedProp = changes[propName];
		  if (changedProp.currentValue == null)
		   {
			updateTable = false;
			break;
		   } 
		   //var cval = changedProp.currentValue;
		  let to = JSON.stringify(changedProp.currentValue);
			updateTable = true;
			break;
		}
		
		//console.log(cval);

		if(this.resetTable){ 
			updateTable = true;
		}
	  
		// console.log(updateTable);
		if (updateTable)
		{  
			if (this.activeLevel === 'D')
			{	
				// console.log('active -D');
				if (this.activeEPData)
				{  
					 this.tableTitle = DataHelper.getDistrictTableTitle(this.activeSector, false);
					// this.epProps = Array.from(DataHelper.getAllDistrictDisplayProperties(this.activeSector, this.stateName));
					// this.epHeaders = DataHelper.getDistrictDataHeaders(this.activeSector, this.stateName);
					//this.tableTitle = DataHelper.getTableTitle(this.activeSector, '');
					this.stateName = this.activeEPData.get('STATE_UT');
					if (! this.stateName)
						this.stateName = this.activeEPData.get('State');
					var distData = DataHelper.getSelectedDistrictData(this.activeSector, this.stateName, this.activeDistrict);
					var arrValues:any[] = [];
					var headers:any[] = [];
					if (distData)
					{
						arrValues = Array.from(distData);
					 	headers = arrValues.shift();
						this.epHeaders = arrValues.shift();
					}
					else
					{
						this.epHeaders = [];
					}
					
					this.epProps = arrValues;
					this.activeEPData=null;	
				}
				else{ 

					this.tableTitle = DataHelper.getDistrictTableTitle(this.activeSector, false);
					// Set different headers and country data for District Combined tab
                    if(this.activeSector==='Combined'){ 
						this.epHeaders = DataHelper.getCountryDataHeaders("Combined_PR");
					    this.epProps = DataHelper.getCountryData("Combined_PR");

					}
					else{
						this.epHeaders = DataHelper.getCountryDataHeaders(this.activeSector);
					    this.epProps = DataHelper.getCountryData(this.activeSector);
						
					}	
								
				}
			}
			else
			{	
				//console.log(this.activeEPData);
				if(this.activeEPData) { 
					//this.tableTitle = DataHelper.getTableTitle(this.activeSector, 'Sectoral');
					this.tableTitle = DataHelper.getTableTitle(this.activeSector, '');
					this.stateName = this.activeEPData.get('STATE_UT');
					if (! this.stateName)
						this.stateName = this.activeEPData.get('State');
					var stateData = DataHelper.getStateData(this.activeSector, this.stateName);
					let arrValues:any[] = Array.from(stateData);
					this.epHeaders = arrValues.shift();
					if (stateData.get('DistrictData'))
						arrValues.pop();
					this.epProps = arrValues;

					if (this.activeSector == "Combined")
					{

					    headers = [];
						headers.push("SECTOR");
						this.epProps = [];
						var stateData = DataHelper.getStateData("Cattle", this.stateName);
						if (stateData)
						{
							
							let arrValues1:any[] = Array.from(stateData);
							if (stateData.get('DistrictData'))
								arrValues1.pop();
							var cattleProps = [];
							if (arrValues1.length >=4)
							{
								headers.push("SOLID WASTE GENERATION\n('000 TONS)");
								headers.push("LIQUID WASTE GENERATION\n(MLD)");
								headers.push(arrValues1[2][0]);
								headers.push(arrValues1[3][0]);
								cattleProps.push("Cattle");
								cattleProps.push(arrValues1[1][1]);
								cattleProps.push('');
								cattleProps.push(arrValues1[2][1]);
								cattleProps.push(arrValues1[3][1]);
							}	
							
							this.epProps.push(cattleProps);
						}

						var stateData = DataHelper.getStateData("Poultry", this.stateName);
						if (stateData)
						{
							let arrValues2:any[] = Array.from(stateData);
							if (stateData.get('DistrictData'))
								arrValues2.pop();
							var poultryProps = [];
							if (arrValues2.length >=4)
							{
								poultryProps.push("Poultry");
								poultryProps.push(arrValues2[1][1]);
								poultryProps.push('');
								poultryProps.push(arrValues2[2][1]);
								poultryProps.push(arrValues2[3][1]);
							}	
							
							this.epProps.push(poultryProps);
						}

						var stateData = DataHelper.getStateData("PressMud", this.stateName);
						if (stateData)
						{
							let arrValues3:any[] = Array.from(stateData);
							if (stateData.get('DistrictData'))
								arrValues3.pop();
							var sfProps = [];
							if (arrValues3.length >=4)
							{
								sfProps.push("Press Mud");
								sfProps.push(arrValues3[1][1]);
								sfProps.push('');
								sfProps.push(arrValues3[2][1]);
								sfProps.push(arrValues3[3][1]);
							}
							this.epProps.push(sfProps);
						}
						var stateData = DataHelper.getStateData("Fruits and Vegetables", this.stateName);
						if (stateData)
						{
							let arrValues4:any[] = Array.from(stateData);
							if (stateData.get('DistrictData'))
								arrValues4.pop();
							var fvProps = [];
							if (arrValues4.length >=4)
							{
								fvProps.push("Fruits and Vegetables");
								fvProps.push(arrValues4[7][1]);
								fvProps.push('');
								fvProps.push(arrValues4[8][1]);
								fvProps.push(arrValues4[9][1]);
							}
							this.epProps.push(fvProps);
						}

						var stateData = DataHelper.getStateData("Pulp & Paper", this.stateName);
						if (stateData)
						{
							let arrValues4:any[] = Array.from(stateData);
							if (stateData.get('DistrictData'))
								arrValues4.pop();
							var ppProps = [];
							if (arrValues4.length >=4)
							{
								ppProps.push("Pulp & Paper");
								ppProps.push('');
								ppProps.push(arrValues4[1][1]);
								ppProps.push(arrValues4[2][1]);
								ppProps.push(arrValues4[3][1]);
							}
							this.epProps.push(ppProps);
						}

						var stateData = DataHelper.getStateData("Distillery", this.stateName);
						if (stateData)
						{
							let arrValues4:any[] = Array.from(stateData);
							if (stateData.get('DistrictData'))
								arrValues4.pop();
							var distProps = [];
							if (arrValues4.length >=4)
							{
								distProps.push("Distillery");
								distProps.push('');
								distProps.push(arrValues4[1][1]);
								distProps.push(arrValues4[2][1]);
								distProps.push(arrValues4[3][1]);
							}
							this.epProps.push(distProps);
						}

						var stateData = DataHelper.getStateData("Slaughterhouse", this.stateName);
						if (stateData)
						{
							let arrValues4:any[] = Array.from(stateData);
							if (stateData.get('DistrictData'))
								arrValues4.pop();
							var shProps = [];
							if (arrValues4.length >=5)
							{
								shProps.push("Slaughterhouse");
								shProps.push(arrValues4[1][1]);
								shProps.push(arrValues4[2][1]);
								shProps.push(arrValues4[3][1]);
								shProps.push(arrValues4[4][1]);
							}
							this.epProps.push(shProps);
						}

						var stateData = DataHelper.getStateData("Urban Organic Liquid Waste", this.stateName);
						if (stateData)
						{
							let arrValues4:any[] = Array.from(stateData);
							if (stateData.get('DistrictData'))
								arrValues4.pop();
							var ulwProps = [];
							if (arrValues4.length >=4)
							{
								ulwProps.push("Urban Organic Liquid Waste");
								ulwProps.push('');
								ulwProps.push(arrValues4[1][1]);
								ulwProps.push(arrValues4[2][1]);
								ulwProps.push(arrValues4[3][1]);
							}
							this.epProps.push(ulwProps);
						}

						var stateData = DataHelper.getStateData("Urban Organic Solid Waste", this.stateName);
						if (stateData)
						{
							let arrValues4:any[] = Array.from(stateData);
							if (stateData.get('DistrictData'))
								arrValues4.pop();
							var uswProps = [];
							if (arrValues4.length >=4)
							{
								uswProps.push("Urban Organic Solid Waste");
								uswProps.push(arrValues4[1][1]);
								uswProps.push('');
								uswProps.push(arrValues4[2][1]);
								uswProps.push(arrValues4[3][1]);
							}
							this.epProps.push(uswProps);
						}

						this.epHeaders = headers;
					}
					this.activeEPData=null;
				}
				else
				{  
					this.tableTitle = DataHelper.getTableTitle(this.activeSector, 'Statewise');
					this.epHeaders = DataHelper.getCountryDataHeaders(this.activeSector);
					this.epProps = DataHelper.getCountryData(this.activeSector);
					this.activeEPData=null;
				}
			}	

            this.data =[];       
		
			for (var i = 0; i < this.epProps.length; i++){	
				let pusheditems = {};
				var j=0;
				for (var headVal of this.epHeaders) {
					pusheditems[headVal] = this.epProps[i][j];
					j++;
				}
				this.data.push(pusheditems); 
				
			}
				
			this.displayedColumns= this.epHeaders;
			this.dataSource = new MatTableDataSource(this.data);

			if(this.data.length===0){ 
				this.distHasData=false;
			}
			else{
				this.distHasData=true;
			}
		}
	
		if(this.activeLevel=='D'){
			this.districtDataAvailable=false;
		}

	}

	viewDistrictData(){ 
		this.viewDistrictDataEvent.emit(this.activeLevel='D');
	}
	  	
	public generatePDF(){
		if(this.activeEPData){
            this.print();
		}
		else {
			this.captureScreen();			
		}
	}
	public captureScreen()  
	{  
		var data = document.getElementById('captureTable');  
		html2canvas(data).then(canvas => { 

		// Few necessary setting options  
		var imgWidth = 208;   
		var pageHeight = 295;    
		var imgHeight = canvas.height * imgWidth / canvas.width;  
		var heightLeft = imgHeight; 	
		//const contentDataURL = canvas.toDataURL('image/png') 
		const contentDataURL = canvas.toDataURL('image/jpeg', 1.0); 
		let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
		var position = 0;  
		//pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight) 
		pdf.setFontSize(11);
		var finalY = pdf.lastAutoTable.finalY || 10;
		var width = pdf.internal.pageSize.getWidth();
        //pdf.text(this.tableTitle, 14, finalY + 15);
		pdf.text(this.tableTitle, width/2, finalY + 15,{ align: 'center' });

        pdf.addImage(contentDataURL, 'JPEG', 0, finalY+20, imgWidth, imgHeight) 
		//pdf.addImage(contentDataURL, 'JPEG', 0, position, imgWidth, imgHeight, '', 'FAST');
		pdf.save('DataTable.pdf'); // Generated PDF   
		});  
	}  

	print = () => {
		let doc = new jsPDF();
		doc.setFontSize(11);
		var finalY = doc.lastAutoTable.finalY || 10;
		var width = doc.internal.pageSize.getWidth();

        //doc.text(this.tableTitle, 14, finalY + 15);
		doc.text(this.tableTitle, width/2, finalY + 15,{ align: 'center' });

		doc.autoTable({
		  startY: finalY + 20,
		  head: [this.displayedColumns],
		  body: this.epProps,
		  styles: { cellPadding: 0.5, fontSize: 8 }
		});
		doc.save('DataTable.pdf')
	}

	exportExcel() {
		const workSheet = XLSX.utils.json_to_sheet(this.data, {header:this.epHeaders});
			const workBook: XLSX.WorkBook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workBook, workSheet, 'SheetName');
			XLSX.writeFile(workBook, 'DataTable.xlsx');
	}
	
	roundDecimalPoint(num){
		return DataHelper.roundDecimalPoint(num);
	}

    tableBgColor(){
	  var colorCode="";
	  let sectorKey= this.activeSector;
	  sectorKey = (sectorKey=='Fruits and Vegetables') ? 'FruitVeg' :sectorKey;
	  sectorKey = (sectorKey=='Press Mud') ? 'PressMud' :sectorKey;

	  if(sectorKey){		
		colorCode=  DataHelper.getSelectedSectorColor('', sectorKey);
	  }
	  colorCode= colorCode+'10';
	  return  colorCode;
   }

   public captureImage()  
	{  
		var data = document.getElementById('captureTable');  
		html2canvas(data).then(canvas => { 		
		 const contentDataURL = canvas.toDataURL('image/png') 
		 //const contentDataURL = canvas.toDataURL('image/jpeg', 1.0); 
		 this.imageURI=contentDataURL;
		 
		});  
	}  


	showModal():void {     
		$("#popContent").modal({
		  backdrop: 'static',
		  keyboard: false
	
		}); 
	  }
	
	  hideModal():void {  //document.getElementById('close-modal-sector').click();
	 
		$('#popContent').modal('hide');
	  }

	  tblMaxWidth(){
		var width=null;
		if (this.activeEPData){ 
            if(this.activeSector !=="Combined"){
                width=100;
			}
		}
        return width;
	  }
}