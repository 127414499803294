import * as XLSX from 'xlsx';
import { stringify } from 'querystring';
import { flatten } from '@angular/compiler';
import { stat } from 'fs';

export class DataHelper {
    public static StateData:Map<string, Map<string, any>> = new Map(); 

    public static getDistrictData(sector:string, state_ut:string):Map<string, any>
    {
        var stateData = DataHelper.StateData.get(state_ut.toUpperCase());
        if(stateData){
            var sectorData = stateData.get(DataHelper.getSectorKey(sector));
            if (sectorData)
            {
                return sectorData.get('DistrictData');
            }    
        }

        return null;
    }

    // sss
    public static getStateDataSSS(sector:string, state_ut:string) : any
    {
        var state = state_ut.toUpperCase();
        //if (state === 'UT' || state === 'LADAKH')
        //if (state === 'UT')
         //   state = 'UTS & OTHERS';
         var stateData = DataHelper.StateData.get(state);
         if (stateData){
             var sectorData = stateData.get(DataHelper.getSectorKey(sector));
             if (sectorData){
                 var ssvalues =  Array.from(sectorData.values());
                 if(ssvalues[1]){
                     return sectorData;
                 }
             }
        }
        return null;
    }
    // 



    public static getAllDistrictDisplayProperties(sector:string, state_ut:string):string[][]
    {        
        var props:string[][] = new Array<Array<string>>();
        var districtData = DataHelper.getDistrictData(sector, state_ut);

        if (districtData)
        {
            var arrData = Array.from(districtData)
            for (let data of arrData)
            {
                var rowData = new Array<string>();
                rowData.push(data[0]);

                data[1].forEach((value: any, key: string) => {
                    if (key.toUpperCase() == 'STATE' || key.toUpperCase() == 'DISTRICT'){
                    }
                    else{
                        rowData.push(value);
                    }
                });
        
                props.push(rowData);
            }
        }
        return props;
    }

    public static getDistrictShare(sector:string, state_ut:string, district:string):string
    {
        var share:string = '';        
         var districtData = DataHelper.getDistrictData(sector, state_ut);
 
        if (districtData)
        {
            var details = districtData.get(district.toUpperCase());
            if (details)
            {                
                //props.set('District', data[0]);
                share = details.get('STATE_SHARE');
            }
        }
        return share;
    }

    public static getDistrictDisplayProperties(sector:string, state_ut:string, district:string):Map<string, string>
    {        
        var props:Map<string, string> = new Map();
        var districtData = DataHelper.getDistrictData(sector, state_ut);

        if (districtData)
        {
            var details = districtData.get(district.toUpperCase());           
            if (details)
            {            
                details.forEach((value: any, key: string) => {
                    if (key.toUpperCase() == 'STATE' || key.toUpperCase() == 'DISTRICT'){
                    }
                    else{
                        props.set(key, value);
                    }
                });
            }
        }
        return props;
    }
  
    public static getCountryOverallDataHeaders():string[]
    {
        var headers:Array<string> = new Array<string>();
        if (DataHelper.StateData)
            headers = Array.from(DataHelper.StateData.get('MAHARASHTRA').get('Overall').keys());
        return headers;
    }

    public static getCountryDataHeaders(sector:string):string[]
    {
        var headers:Array<string> = new Array<string>();
        if (DataHelper.StateData)
            headers = Array.from(DataHelper.StateData.get('TOTAL').get(DataHelper.getSectorKey(sector)).keys());
        if (headers.indexOf ('DistrictData') > 0)
            headers.pop();
        return headers;
    }

    public static getCombinedDistrictData():any
    {
        var out = [];
        var total = [];
        var index = 2;
        var headerKeys = Array.from(DataHelper.StateData.keys());
        for (let headerKey of headerKeys)
        {
            var sectorData = DataHelper.StateData.get(headerKey).get(DataHelper.getSectorKey("Combined"));
            if (sectorData){
                var arrValues = Array.from(sectorData.values());
                var distData = null;
                if (sectorData.get('DistrictData'))
                    distData = arrValues.pop();
                if (distData)
                {
                   // var distValues = distData.values();
                    for (let dData of distData){
                        var arVal = dData.values();
                        out.push(arVal); 
                    }
                }
                
                }
        }

        // var sortedArr = out.sort ((n1,n2) => {
            
        //     var ep1 = Number(n1[index]);
        //     if (n1[index] == '-')
        //         ep1 = 0;
        //     var ep2 = Number(n2[index]);
        //     if (n2[3] == '-')
        //         ep2 = 0;
        //     if (ep1 > ep2)
        //         return -1;
        //     else
        //         return 1;
        // });
        // if (total)
        //     sortedArr.push(total);

        return out;
    }

    public static getStateOverallDataHeaders(state:string):string[]
    {
        var headers:Array<string> = new Array<string>();
        // if (DataHelper.StateData)
        //     headers = Array.from(DataHelper.StateData.get(state).get('WasteGeneration').keys());
        headers.push("Sector"); 
        headers.push("Waste Type"); 
        headers.push("Waste Generation - Solid Waste (Million Ton), Liquid Waste (Million m3)");
        headers.push("Energy Potential (MW)");
        headers.push("Bio-CNG Potential (Tons/Day)");
        return headers;
    }

    public static getDistrictDataHeaders(sector:string, state_ut:string):string[]
    {
        var headers:Array<string> = new Array<string>();

        var districtData = DataHelper.getDistrictData(sector, state_ut);

        if (districtData)
        {
            var arrData = Array.from(districtData)
            for (let data of arrData)
            {
                headers.push('DISTRICT');

                data[1].forEach((value: any, key: string) => {
                    if (key.toUpperCase() == 'STATE' || key.toUpperCase() == 'DISTRICT'){
                    }
                    else{
                        headers.push(key);
                    }
                });
                break;    
            }
        }
        return headers;
    }

// (Solid Waste in Million Tons and Liquid Waste in Million m3)
    public static getCountryOverallData():any
    {        
        var out = [];
        var headerKeys = Array.from(DataHelper.StateData.keys());
        for (let headerKey of headerKeys)
        {
            var overallData = DataHelper.StateData.get(headerKey).get('Overall');
            if (overallData){
                out.push(Array.from(overallData.values()));    
            }
        }
        return out;
    }

    public static getStateOverallData(state: string):any
    {        
        var out = [];
        var totalLiquid = [];
        var totalSolid = [];
        var othersLiquid = [];
        var othersSolid = [];
        var stateKeys = DataHelper.StateData.get(state.toUpperCase());
        if (stateKeys)
        {
            var wasteGenerationData = stateKeys.get("WasteGeneration");
            var bcpData = stateKeys.get("BCP");
            var epData = stateKeys.get("EP");

            var headerKeys = Array.from(wasteGenerationData.keys());
            for (let headerKey of headerKeys)
            {
                if (headerKey === 'State')
                    continue;
                var sectorKey = <string>headerKey;
                var arr = sectorKey.split("-")

                var waste = wasteGenerationData.get(headerKey);
                var bcp = bcpData.get(headerKey);

                
                if (sectorKey.includes('TOTAL - Liquid'))
                {
                    var ep = epData.get('TOTAL');
                    var bcp = bcpData.get('TOTAL');
                    totalLiquid = [arr[0], arr[1], waste, ep, bcp];
                }
                else  if (sectorKey.includes('TOTAL - Solid'))
                {
                    var ep = epData.get('TOTAL');
                    var bcp = bcpData.get('TOTAL');
                    totalSolid = [arr[0], arr[1], waste, ep, bcp];
                }
                else if (sectorKey.includes('OTHERS - Liquid'))
                {
                    var ep = epData.get('OTHERS');
                    var bcp = bcpData.get('TOTAL');
                    othersLiquid = [arr[0], arr[1], waste, ep, bcp];
                }
                else if (sectorKey.includes('OTHERS - Solid'))
                {
                    var ep = epData.get('OTHERS');
                    var bcp = bcpData.get('TOTAL');
                    othersSolid = [arr[0], arr[1], waste, ep, bcp];
                }
                else
                {
                    var ep = epData.get(headerKey);
                    var bcp = bcpData.get(headerKey);
                    out.push([arr[0], arr[1], waste, ep, bcp]);
                }
            }
        }

        var sortedArr = out.sort ((n1,n2) => {
            
            var ep1 = Number(n1[3]);
            if (n1[3] == '-')
                ep1 = 0;
            var ep2 = Number(n2[3]);
            if (n2[3] == '-')
                ep2 = 0;
            if (ep1 > ep2)
                return -1;
            else
                return 1;
        });
        sortedArr.push(othersLiquid);
        sortedArr.push(othersSolid);
        sortedArr.push(totalLiquid);
        sortedArr.push(totalSolid);
        return sortedArr;
    }

    public static getCountryData(sector:string):any
    {
        var out = [];
        var total = [];
        var index = 2;
        var headerKeys = Array.from(DataHelper.StateData.keys());
        for (let headerKey of headerKeys)
        {
            var sectorData = DataHelper.StateData.get(headerKey).get(DataHelper.getSectorKey(sector));
            if (sectorData){
                var arrValues = Array.from(sectorData.values());
                if (sectorData.get('DistrictData'))
                    arrValues.pop();
                if (headerKey.toUpperCase() === 'TOTAL')
                {
                    total = arrValues;
                }
                else{
                    out.push(arrValues); 
                }
                index = arrValues.length - 1;
            }
        }

        var sortedArr = out.sort ((n1,n2) => {
            
            var ep1 = Number(n1[index]);
            if (n1[index] == '-')
                ep1 = 0;
            var ep2 = Number(n2[index]);
            if (n2[3] == '-')
                ep2 = 0;
            if (ep1 > ep2)
                return -1;
            else
                return 1;
        });
        if (total)
            sortedArr.push(total);

        return sortedArr;
    }

    public static getCountryOverallDataTitle():string{
       return "Statewise Waste Generation, Bio-CNG Potential and Energy Potential Estimate";
    }

    public static getStateOverallDataTitle(state:string):string{
        //return "Sectoral Waste Generation, Bio-CNG Potential and Energy Potential Estimate";
        return "Waste Generation, Bio-CNG Potential and Energy Potential Estimate";
    }

    public static getDistrictTableTitle(sector:string, stateSelected:boolean):string
    {
        
        var tableTitle:string = "Waste Generation, Energy Potential and Bio-CNG Potential of ";
        if (stateSelected)
        {
            tableTitle = "Share of Districts in Energy Potential of Waste Generation from ";
        }
        tableTitle += DataHelper.getSectorDescription(sector);
        return tableTitle;
    }

    public static getTableTitle(sector:string, prefix:string):string
    {
        var tableTitle:string = prefix + " Waste Generation, Bio-CNG Potential and Energy Potential Estimate";
        switch(sector)
        {
            case 'Combined':
                    tableTitle += " of Selected Sectors";
                break;
            case 'Overall':
                    tableTitle += "All Sectors";
                break;
            default:
                tableTitle += " from " + DataHelper.getSectorDescription(sector);
                break;
        }       
        return tableTitle;
    }

    public static getSectorDescription(sectorName: string ): string
    {
        var tableTitle = sectorName;
        
        switch(sectorName)
        {
        case 'Poultry':
            tableTitle = "Poultry Farm";
            break;
        case 'Cattle':
            tableTitle = "Cattle Farm";
            break;
        case 'Fruits & Vegetables':
            tableTitle = "Fruits and Vegetables Processing Industry";
            break;
        case 'Sugar Factory':
        case 'PressMud':
        case 'Press Mud':
            tableTitle = "Press Mud";
            break;
        case 'Pulp & Paper':
            tableTitle = "Pulp & Paper Industry";
            break;
        case 'Slaughter House':
            tableTitle = "Slaughterhouse";
            break;
        case 'Urban Solid Waste':
            tableTitle = "Urban Organic Solid Waste";
            break;
        case 'Urban Liquid Waste':
            tableTitle = "Urban Organic Liquid Waste";
            break;
        case 'Combined':
            tableTitle = "Selected Sectors";
            break;   
        default:
            tableTitle = sectorName;
            break;
        }

        return tableTitle;
    }

    public static getSectorKey(sectorTitle : string):string
    {
        var sectorKey:string = sectorTitle;
        switch(sectorTitle)
        {
            case 'Sugar Factory':
            case 'PressMud':
            case 'Press Mud':
                sectorKey = "PressMud";
                break;
            case 'Fruits and Vegetables':
                sectorKey = "FruitVeg";
                break;
            case 'Pulp & Paper':
                sectorKey = "PulpPaper";
                break;
            case 'Urban Organic Liquid Waste':
            case 'Urban Liquid Waste':
                sectorKey = "ULW";
                break;
            case 'Slaughter House':
            case 'Slaughterhouse':
                sectorKey = "Slaughterhouse";
                break;
            case 'Urban Organic Solid Waste':
            case 'Urban Solid Waste':
                sectorKey = "USW";
                break;
            case 'Combined_PR':
                sectorKey = "Combined_PR";
                break;
            default:
                sectorKey = sectorTitle;
                break;
        }
        return sectorKey;
    }

    public static getStateDataHeaders(sector:string, state_ut:string) : any
    {
        var stateData = DataHelper.StateData.get(state_ut.toUpperCase());
        if (stateData){
            var sectorData = stateData.get(DataHelper.getSectorKey(sector));
            if (sectorData)
                return Array.from(sectorData.keys());
        }
        return null;
    }
    
    public static getStateData(sector:string, state_ut:string) : any
    {
        var state = state_ut.toUpperCase();
        //if (state === 'UT' || state === 'LADAKH')
        //if (state === 'UT')
         //   state = 'UTS & OTHERS';
        var stateData = DataHelper.StateData.get(state);
        if (stateData){
            var sectorData = stateData.get(DataHelper.getSectorKey(sector));
            if (sectorData)
                return sectorData;
        }
        return null;
    }

    public static getSelectedDistrictData(sector:string, state_ut: string, district:string) : any
    {
        var districtData = this.getDistrictData(sector, state_ut);
        if (districtData)
        {
            return districtData.get(district);
        }
        return null;
    }

    public static getStateSectorData(state_ut:string) : Map<string, any>
    {
        return DataHelper.StateData.get(state_ut);
    }

    private static makeRequest(method, url, success, error) {
        var httpRequest = new XMLHttpRequest();
        httpRequest.open("GET", url, true);
        httpRequest.responseType = "arraybuffer";
  
        httpRequest.open(method, url);
        httpRequest.onload = function () {
            success(httpRequest.response);
        };
        httpRequest.onerror = function () {
            error(httpRequest.response);
        };
        httpRequest.send();
    }

    static readWorksheet(worksheet, stateKey, dataKey)
    {
          var jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false });  

          for(let data of jsonData){      
                var keyData = Object.keys(data);
                var state_ut:string = data[stateKey]; 
                if (! state_ut)
                    continue;              
                var props: Map<string, any> = new Map();
                for(let key of keyData){     
                    props.set(key, data[key]);
                }

                var stateProp = DataHelper.StateData.get(state_ut.toUpperCase());
                if (!stateProp){
                    stateProp = new Map();
                }
                stateProp.set(dataKey, props);
                DataHelper.StateData.set(state_ut.toUpperCase(), stateProp);
            }
    }

    static loadCountryData(path: string) {
        this.makeRequest('GET', path, function (data) {

            var data1 = new Uint8Array(data);
            var arr = new Array();
      
            for (var i = 0; i !== data1.length; ++i) {
                arr[i] = String.fromCharCode(data1[i]);
            }      
            var bstr = arr.join("");      
            var workbook = XLSX.read(bstr, {type: "binary"});
        
            var worksheet = workbook.Sheets["CONSOLIDATED"];
            DataHelper.readWorksheet(worksheet, 'State', 'Overall');

            worksheet = workbook.Sheets["WASTE GENERATION"];
            DataHelper.readWorksheet(worksheet, 'State', 'WasteGeneration');
            
            worksheet = workbook.Sheets["EP SECTORS"];
            DataHelper.readWorksheet(worksheet, 'State', 'EP');

            worksheet = workbook.Sheets["BCP_SECTORS"];
            DataHelper.readWorksheet(worksheet, 'State', 'BCP');

            DataHelper.loadPrioritySectorsData('/assets/LAYER 2_STATE DATA.xlsx');
              
        },
        // error
        function (error) {
            throw error;
        });

        return true;
    }

    static loadPrioritySectorsData(path: string) {
        this.makeRequest('GET', path, function (data) {

            var data1 = new Uint8Array(data);
            var arr = new Array();
      
            for (var i = 0; i !== data1.length; ++i) {
                arr[i] = String.fromCharCode(data1[i]);
            }      
            var bstr = arr.join("");      
            var workbook = XLSX.read(bstr, {type: "binary"});

            var worksheet = workbook.Sheets["EP_COMBINED"];
            DataHelper.readWorksheet(worksheet, 'STATE_UT', 'Combined');        

            worksheet = workbook.Sheets["EP_PR_COMBINED"];
            DataHelper.readWorksheet(worksheet, 'STATE_UT', 'Combined_PR');

            worksheet = workbook.Sheets["EP_POULTRY"];
            DataHelper.readWorksheet(worksheet, 'STATE_UT', 'Poultry');

            worksheet = workbook.Sheets["EP_PRESS_MUD"];
            DataHelper.readWorksheet(worksheet, 'STATE_UT', 'PressMud');

            worksheet = workbook.Sheets["EP_FRUIT_VEG"];
            DataHelper.readWorksheet(worksheet, 'STATE_UT', 'FruitVeg');

            worksheet = workbook.Sheets["EP_CATTLE"];
            DataHelper.readWorksheet(worksheet, 'STATE_UT', 'Cattle');

            worksheet = workbook.Sheets["EP_PULP_PAPER"];
            DataHelper.readWorksheet(worksheet, 'STATE_UT', 'PulpPaper');

            worksheet = workbook.Sheets["EP_SLAUGHTERHOUSE"];
            DataHelper.readWorksheet(worksheet, 'STATE_UT', 'Slaughterhouse');

            worksheet = workbook.Sheets["EP_ULW"];
            DataHelper.readWorksheet(worksheet, 'STATE_UT', 'ULW');

            worksheet = workbook.Sheets["EP_USW"];
            DataHelper.readWorksheet(worksheet, 'STATE_UT', 'USW');

            worksheet = workbook.Sheets["EP_DISTILLERY"];
            DataHelper.readWorksheet(worksheet, 'STATE_UT', 'Distillery');

            

            DataHelper.loadDistrictData('/assets/DISTRICT_DATA.xlsx');
        },
        // error
        function (error) {
            throw error;
        });

        return true;
    }

    static loadDistrictData(path: string) {
         this.makeRequest('GET', path, function (data) {

            var data1 = new Uint8Array(data);
            var arr = new Array();
      
            for (var i = 0; i !== data1.length; ++i) {
                arr[i] = String.fromCharCode(data1[i]);
            }      
            var bstr = arr.join("");      
            var workbook = XLSX.read(bstr, {type: "binary"});
        
                workbook.SheetNames.forEach(sheetName => {
                var worksheet = workbook.Sheets[sheetName];
                var jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false });  
    
                var districtProp:Map<string, any> = new Map();
                var prev_state_ut:string = ''; 
    
                var sector = '';
                if (sheetName === 'EP_POULTRY'){
                    sector = 'Poultry';
                }
                else if (sheetName === 'EP_CATTLE'){
                    sector = 'Cattle';
                }
                else if (sheetName === 'EP_SUGAR_FACTORY'){
                    sector = 'PressMud';
                }
                else if (sheetName === 'EP_PULP_PAPER'){
                    sector = 'PulpPaper';
                }
                else if (sheetName === 'EP_DISTILLERY'){
                    sector = 'Distillery';
                }
                else if (sheetName === 'EP_SLAUGHTERHOUSE'){
                    sector = 'Slaughterhouse';
                }
                else if (sheetName === 'EP_USW'){
                    sector = 'USW';
                }
                else if (sheetName === 'EP_ULW'){
                    sector = 'ULW';
                }
                else if (sheetName === 'EP_COMBINED'){
                    sector = 'Combined';
                }
                // else if (sheetName === 'EP_PR_COMBINED'){
                //     sector = 'Combined_PR';
                // }
                
                if (sector != '')
                {
                    for(let data of jsonData){      
                        var keyData = Object.keys(data);
                        var state_ut:string = data['State'];  
                        var district:string = data['District'];         

                        if(prev_state_ut !== '' && prev_state_ut !== state_ut)
                        {
                            var stateData = DataHelper.StateData.get(prev_state_ut.toUpperCase());
                            if (stateData && stateData.get(sector))
                                stateData.get(sector).set('DistrictData', districtProp);
                            districtProp = new Map();
                        }
                        prev_state_ut = state_ut;

                        var props: Map<string, any> = new Map();
                        for(let key of keyData){    
                            var dataVal = data[key];
                            if (!dataVal){
                                dataVal = "";
                            } 
                            props.set(key, dataVal);
                        }
                        if (district)
                            districtProp.set(district.toUpperCase(), props);
                        
                    }
                    if (prev_state_ut){
                        var stateData = DataHelper.StateData.get(prev_state_ut.toUpperCase());
                        stateData.get(sector).set('DistrictData', districtProp);
                    }
                }
               
                });
            },
            // error
            function (error) {
                throw error;
            });
    }

    
   public static convertToNumber(value:any):any
   {
     return parseFloat(value.replace(/,/g, ''));
   }

   public static getPrioritySectorData(stateKey:string, sector:string, dataHeader:string, out:any, slicesColor:any,activeLevel:any,activeSector:any,visibleInLegend:any, districtKey:string)
   {
      var sectorKey = DataHelper.getSectorKey(sector);
      var sectorData = DataHelper.StateData.get(stateKey).get(sectorKey);      
      if (sectorData)
      {        
        if (districtKey && activeLevel === 'D')
        {
            var districtData = sectorData.get('DistrictData');
            if (districtData)
                sectorData = districtData.get(districtKey);
        }
        if (sectorData)
        {
            sectorData.forEach((value: any, key: string) => {        
            if (key.includes(dataHeader))
                {
                    out.push([sector,DataHelper.convertToNumber(value)]);
                    if(activeLevel !=='C' && activeSector !=='Combined'){                   
                        slicesColor.push(DataHelper.getSelectedSectorColor(activeSector,sectorKey));
                        visibleInLegend.push(DataHelper.getChartLegendStatus(activeSector,sectorKey));                    
                    }
                    else{
                        slicesColor.push(DataHelper.getSectorColor(sectorKey));
                    }
                    
                }
            });
        }
      }
   }

   public static getPrioritySectorsChartData(state:string, dataHeader: string,activeLevel:string,activeSector:any,wasteType:any='', activeDistrict:string = null):any
   {
        //console.log(wasteType);
        var out = [];
        var stateKey = state;
        var districtKey = activeDistrict;
        
        if (!state || state.length === 0)
        {
            stateKey = 'TOTAL';
            out.push(["STATE",""]);
        }
        else{
            stateKey = state.toUpperCase();
            if (activeLevel == 'D' && activeDistrict)
            {
                districtKey = activeDistrict.toUpperCase();
                out.push(["DISTRICT",districtKey]);    
            }
            else
            {
                out.push(["STATE",stateKey]);
            }
        }
        var slicesColor=[];
        var visibleInLegend=[];
        var epData = false;

        if(dataHeader !== "WASTE GENERATION"){
            DataHelper.getPrioritySectorData(stateKey, 'Poultry', dataHeader, out, slicesColor,activeLevel,activeSector,visibleInLegend, districtKey);
            DataHelper.getPrioritySectorData(stateKey, 'Cattle', dataHeader, out, slicesColor,activeLevel,activeSector,visibleInLegend, districtKey);
            DataHelper.getPrioritySectorData(stateKey, 'Press Mud', dataHeader, out, slicesColor,activeLevel,activeSector,visibleInLegend, districtKey);
            if (activeLevel == 'S')
                DataHelper.getPrioritySectorData(stateKey, 'Fruits and Vegetables', dataHeader, out, slicesColor,activeLevel,activeSector,visibleInLegend, districtKey);
            DataHelper.getPrioritySectorData(stateKey, 'Pulp & Paper', dataHeader, out, slicesColor,activeLevel,activeSector,visibleInLegend, districtKey);
            DataHelper.getPrioritySectorData(stateKey, 'Distillery', dataHeader, out, slicesColor,activeLevel,activeSector,visibleInLegend, districtKey);
            DataHelper.getPrioritySectorData(stateKey, 'Urban Organic Liquid Waste', dataHeader, out, slicesColor,activeLevel,activeSector,visibleInLegend, districtKey);
            DataHelper.getPrioritySectorData(stateKey, 'Urban Organic Solid Waste', dataHeader, out, slicesColor,activeLevel,activeSector,visibleInLegend, districtKey);
            if (activeLevel == 'D' && activeDistrict)
                dataHeader = 'TOTAL ENERGY POTENTIAL';
            DataHelper.getPrioritySectorData(stateKey, 'Slaughterhouse', dataHeader, out, slicesColor,activeLevel,activeSector,visibleInLegend, districtKey);

        }
        else if (wasteType == 'Solid Waste')
        {
            DataHelper.getPrioritySectorData(stateKey, 'Poultry', dataHeader, out, slicesColor,activeLevel,activeSector,visibleInLegend, districtKey);
            DataHelper.getPrioritySectorData(stateKey, 'Cattle', dataHeader, out, slicesColor,activeLevel,activeSector,visibleInLegend, districtKey);
            DataHelper.getPrioritySectorData(stateKey, 'Press Mud', 'PRESS MUD GENERATION', out, slicesColor,activeLevel,activeSector,visibleInLegend, districtKey);
            if (activeLevel == 'S')
                DataHelper.getPrioritySectorData(stateKey, 'Fruits and Vegetables', 'TOTAL WASTE', out, slicesColor,activeLevel,activeSector,visibleInLegend, districtKey);
            DataHelper.getPrioritySectorData(stateKey, 'Urban Organic Solid Waste', dataHeader, out, slicesColor,activeLevel,activeSector,visibleInLegend, districtKey);
            if (activeLevel == 'D' && activeDistrict)
                dataHeader = 'SOLID WASTE GENERATION';
            else
                dataHeader = 'SOLID WASTE'
            DataHelper.getPrioritySectorData(stateKey, 'Slaughterhouse', dataHeader, out, slicesColor,activeLevel,activeSector,visibleInLegend, districtKey);
        } 
        else if (wasteType == 'Liquid Waste')
        {
            DataHelper.getPrioritySectorData(stateKey, 'Pulp & Paper', dataHeader, out, slicesColor,activeLevel,activeSector,visibleInLegend, districtKey);
            DataHelper.getPrioritySectorData(stateKey, 'Distillery', 'SPENTWASH GENERATION', out, slicesColor,activeLevel,activeSector,visibleInLegend, districtKey);
            DataHelper.getPrioritySectorData(stateKey, 'Urban Organic Liquid Waste', 'SEWAGE GENERATION', out, slicesColor,activeLevel,activeSector,visibleInLegend, districtKey);
            if (activeLevel == 'D' && activeDistrict)
                dataHeader = 'LIQUID WASTE GENERATION';
            else
                dataHeader = 'LIQUID WASTE';
            DataHelper.getPrioritySectorData(stateKey, 'Slaughterhouse', dataHeader, out, slicesColor,activeLevel,activeSector,visibleInLegend, districtKey);
        }
        var tempArr=[];
        tempArr.push(out,slicesColor,visibleInLegend);        
        return tempArr;
   }

   public static energyPotentialChartData(state:string):any
   {
        var out = [];
        var stateKey = state;
        
        if (!state || state.length === 0)
        {
            stateKey = 'TOTAL';
            out.push(["STATE",""]);
        }
        else{
            stateKey = state.toUpperCase();
            out.push(["STATE",stateKey]);
        }

        var headerKeys = Array.from(DataHelper.StateData.get(stateKey).get('EP').keys());
        var slicesColor=[];

        for (let key of headerKeys)
        {                
            var keyStr = <string>key;
            if (! keyStr.includes('Total') && ! keyStr.includes('TOTAL') && !keyStr.includes('State'))//if (key.value.)
            {
                var value = DataHelper.StateData.get(stateKey).get('EP').get(key);
                var label = keyStr.split('-');
                out.push([label[0],DataHelper.convertToNumber(value)]);
                slicesColor.push(DataHelper.getSectorColor(key));
            }
        }       
        var tempArr=[];
        tempArr.push(out,slicesColor);
        return tempArr;
   }

   public static wasteGenerationPrioritySectorsChartData(state:string):any
    {
        var out = [];
        out.push(["SECTOR","WASTE GENERATION"]);
        var stateKey = state;
        
        if (!state || state.length === 0)
        {
            stateKey = 'TOTAL';
        }
        else{
            stateKey = state.toUpperCase();
        }

        var headerKeys = Array.from(DataHelper.StateData.get(stateKey).get('WasteGeneration').keys());

        var slicesColor=[];
        for (let key of headerKeys)
        {                
            var keyStr = <string>key;
            if (! keyStr.includes('Total') && ! keyStr.includes('TOTAL') && !keyStr.includes('State'))//if (key.value.)
            {
                var value = DataHelper.StateData.get(stateKey).get('WasteGeneration').get(key);
                out.push([key,DataHelper.convertToNumber(value)]);
                slicesColor.push(DataHelper.getSectorColor(key));
            }
        }       
        
        var tempArr=[];
        tempArr.push(out,slicesColor);
        return tempArr;
   }

   /* Waste Generation Chart Data */ 
   public static wasteGenerationChartData(state:string):any
    {
        var out = [];
        out.push(["SECTOR","WASTE GENERATION"]);
        var stateKey = state;
        
        if (!state || state.length === 0)
        {
            stateKey = 'TOTAL';
        }
        else{
            stateKey = state.toUpperCase();
        }

        var headerKeys = Array.from(DataHelper.StateData.get(stateKey).get('WasteGeneration').keys());

        var slicesColor=[];
        for (let key of headerKeys)
        {                
            var keyStr = <string>key;
            if (! keyStr.includes('Total') && ! keyStr.includes('TOTAL') && !keyStr.includes('State'))//if (key.value.)
            {
                var value = DataHelper.StateData.get(stateKey).get('WasteGeneration').get(key);
                out.push([key,DataHelper.convertToNumber(value)]);
                slicesColor.push(DataHelper.getSectorColor(key));
            }
        }       
        
        var tempArr=[];
        tempArr.push(out,slicesColor);
        return tempArr;
   }

   
   public static getSectorColor(key){ //alert(key);
        var color="";
        switch(key) {
            case "USW":
            case "Urban Solid Waste":
            case "Urban Organic Solid Waste":
            case "MSW - Solid":
                color = '#FFC95D';
                break;
            case "Cattle farm - Solid":
            case "Cattle":
                color = '#8A5A42';
                break;
            case "Fruits and Vegetables":
                color = '#FF00FF';
                break;
            case "F&V Raw - Solid" :
            case "FruitVeg":
                color = '#FF00FF';
                break;
            case "Distillery - Liquid" :
            case "Distillery" :    
                color = '#74B273';
                break;     
            case "Poultry - Solid" :
            case "Poultry":
                color = '#006AFF';
                break; 
            case "ULW":
            case "Urban Liquid Waste":
            case "Urban Organic Liquid Waste":
            case "Sewage - Liquid":
                color = '#FF99CC';
                break;   
            case "Slaughter - Liquid":
                color = '#00FFFF';
                break;                       
            case "Sugar - Solid":
            case "SugarFactory":
            case "Sugar Factory":
            case "PressMud":
            case "PressMud - Solid": 
            case "Press Mud":                
                color = '#4FFF00';
                break; 
            case "Slaughterhouse":
            case "Slaughter House":  
            case "Meat - Liquid":
                color = '#FFEABF';
                break;     
            case "F&V Processing - Solid":
                color = '#FFFF00'; 
                break;   
            case "OTHERS":
                color = '#343a40';
                break;   
            case "PulpPaper":
            case "Pulp & Paper":
            case "Paper - Liquid":
            case "Paper":
                color = '#FFFF00';
                break;
            case "OTHERS - Liquid":
                color = '#FF0000';
                break; 
            case "OTHERS - Solid":
                color = '#808080';
                break;  
            case "Combined":
                color = '#00FFFF';
                break;  
            default: { 
                color = '';
                break; 
            }              
        }

        return color;

    } 

    public static getSelectedSectorColor(sector,key){ 
               
        sector = sector.replace(/\s/g, "");        
        sector = (sector=='FruitsandVegetables') ? 'FruitVeg' :sector;
        var color="";
        color = this.getSectorColor(key);

        // if(sector==key){
        //     color= this.getSectorColor(key);
        // }       
        // else{                        
        //     switch(key) {               
        //         case "Cattle farm - Solid":
        //         case "Cattle":
        //             color = '#EFDDF5';
        //             break;
        //         case "F&V Raw - Solid" :
        //         case "FruitVeg":
        //             color = '#F0E3F4';
        //             break;                   
        //         case "Poultry - Solid" :
        //         case "Poultry":
        //             color = '#D7DDF3';
        //             break;                                    
        //         case "Sugar - Solid":
        //         case "SugarFactory":
        //         case "Sugar Factory":
        //         case "PressMud":
        //             color = '#E8F0E0';
        //             break;                              
        //     }
        // }

        return color;
    }

    public static getChartLegendStatus (sector,key){ 
        /*   
        sector = sector.replace(/\s/g, "");        
        sector = (sector=='FruitsandVegetables') ? 'FruitVeg' :sector;
        var visibleInLegend=false;
        if(sector==key){
            visibleInLegend=true;
        }       
        else {
            visibleInLegend=false;
        }
        return  visibleInLegend;
        */
        var activeSectorKey = DataHelper.getSectorKey(sector);                
        activeSectorKey = (sector=='FruitsandVegetables') ? 'FruitVeg' :activeSectorKey;
        var visibleInLegend=false;
        if(activeSectorKey==key){
            visibleInLegend=true;
        }    
        else {
            visibleInLegend=false;
        }
        return  visibleInLegend;
    }

    //Mahesh-	For decimal values
        
	public static roundDecimalPoint(num){
                
       var hasDecimal= this.checkDecimalPoint(num);
       if(num >1 && (hasDecimal<=2)){
		   return num;
	   }
	   else if(num >1 && (hasDecimal > 2)){            
            var number= String(num);
            var substring:any= '.00';
            if(number.includes(substring)){
                return Math.round((num + Number.EPSILON) * 100) / 100;
            }  
		    return Math.round(num* 100) / 100;            
	   }
       else if(num <1 && (hasDecimal >3)){
		 return  parseFloat(num).toFixed(3)
	   }
	   else 
	   return num;
	}

    public static checkDecimalPoint(num){
		const numStr = String(num);
		if (numStr.includes('.')) {
			return numStr.split('.')[1].length;
		};		
		return 0;
	}
    

}
