import { Component, Input, Output, OnChanges, SimpleChange, AfterViewInit, EventEmitter } from '@angular/core';
import 'leaflet';
import * as shp from 'shpjs';
//import * as L from 'leaflet';
import { KeyValue, KeyValuePipe } from "@angular/common";
import { DataHelper } from '../DataHelper';
import '../../../node_modules/leaflet.browser.print/dist/leaflet.browser.print.min.js';
import '../../../node_modules/leaflet-easybutton/src/easy-button';
import { color } from 'html2canvas/dist/types/css/types/color';

declare let L;

declare var $: any;

@Component({
  selector: 'app-LeafletMap',
  templateUrl: './LeafletMap.component.html',
  styleUrls: ['./LeafletMap.component.css']
})
export class LeafletMapComponent implements AfterViewInit  {

  private map;
  private selLayer;
  private selDistrictLayer;
  private highlightColor = '#DFA612';
  private info = L.control();
  private pntLayer;
  private cntryLayer;
  private stLayer;
  private stLinesLayer;
  private distLayer;
  private distLinesLayer;
  private distPntsLayer;
  private autoZoom:Boolean = true;
  private zoomLevel:Number;
  private prevDistLabelClass:String;

 //selectedFeatureProps = 
  @Input() activeSector: any;
  @Input() activeLevel: string;
  @Input() activeState: string;
  @Input() activeDistrict: string;
  @Output()
  mapSelectionChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  resetMap: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
	  this.activeSector = "Overall";
	  this.activeLevel = 'C';
   }

  ngAfterViewInit () {
	  this.initMap();

	//   const baseLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
	// 	maxZoom: 19,
	// 	attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
	// 	});

		var TooltipClass = {
			'className': 'class-labels'
		  }
		var pointLayer = L.geoJSON(null, {
			pointToLayer: function(feature,latlng){
			  return new L.CircleMarker(latlng, {
				radius: 0.1,
				opacity: 0.01
			  }).bindTooltip(feature.properties['ST_NAME'], {permanent: true, direction: "center", ...TooltipClass}, TooltipClass);
			  }
			});
		var sector = this.activeSector;	
		

		var countryLayer = L.geoJson({features:[]}, {
			style: (feature) => ({
				weight: 4,
				color: '#000000',	
				dashArray: "16 8 1 8"		
			})
		});

		var countryBoundaryLayer = L.geoJson({features:[]}, {
			style: (feature) => ({
				weight: 4,
				color: '#000000',	
				dashArray: "16 8 1 8",
				fillColor: '#FFFFFF',
				fillOpacity: 0.5			
			})
		});


		var stateLinesLayer = L.geoJson({features:[]}, {
			style: (feature) => ({
				weight: 3,
				color: '#654321',	
				dashArray: "8 8",			
			})
		});

		var districtLinesLayer = L.geoJson({features:[]}, {
			style: (feature) => ({
				weight: 1,
				color: '#013220',	
				dashArray: "1 2",			
			})
		});

		var districtLayer = L.geoJson({features:[]}, {
			style: (feature) => ({
				weight: 1,
				color: '#013220',
				dashArray: "1 2",
				opacity: 0,
				//fillColor:'#FF0000',
				fillOpacity: 0.5
			}),
			onEachFeature: (feature, layer) => (
				layer.on({
				  mouseover: (e) => (this.highlightDistrict(e)),
				  mouseout: (e) => (this.resetState(e)),
				  click: (e) => (this.clickDistrict(e)),
				})
			  )			
		});

		var stateLayer = L.geoJson({features:[]}, {
			style: (feature) => ({
				weight: 3,
				color: '#654321',
				dashArray: "4 4",
				opacity: 0.5,
				fillColor: "#FFFFFF", //this.getFillColor(feature),
				fillOpacity: 1
			}),
			onEachFeature: (feature, layer) => (
				layer.on({
				  mouseover: (e) => (this.highlightState(e)),
				  mouseout: (e) => (this.resetState(e)),
				  click: (e) => (this.clickState(e)),
				})
			  )
		});

		var countryBoundary = 'assets/INDIA.zip';
        shp(countryBoundary).then(function(data){
			countryBoundaryLayer.addData(data);
		});

		var country = 'assets/INDIA_LINE.zip';
        shp(country).then(function(data){
			countryLayer.addData(data);
		});

		var base = 'assets/States_UTs.zip';
        shp(base).then(function(data){
			stateLayer.addData(data);
		});
		
		var stateLines = 'assets/INDIA_STATE_LINE.zip';
        shp(stateLines).then(function(data){
			stateLinesLayer.addData(data);
		});

		var points = 'assets/INDIA_STATE_PT.zip';
        shp(points).then(function(data){
			pointLayer.addData(data);
		});

		var dist = 'assets/INDIA_DISTRICT.zip';
        shp(dist).then(function(data){
			districtLayer.addData(data);
		});

		var distLines = 'assets/INDIA_DISTRICT_LINE.zip';
        shp(distLines).then(function(data){
			districtLinesLayer.addData(data);
		});
		

		// var distLines = 'assets/INDIA_DISTRICT_LINE.zip';
        // shp(distLines).then(function(data){
		// 	districtLinesLayer.addData(data);
		// });
		
	
		// var baseMaps = {
		// "BaseLayer": baseLayer
		// };
		// var overlays = {
		// 	    "Basemap": baseLayer,
		// 		"Country" : countryLayer,
		// 		"All States": stateLayer,
		// 		"Labels" : pointLayer	
		// 	};
		// L.control.layers(null,overlays).addTo(this.map);

		//this.map.addLayer(baseLayer);
		this.map.addLayer(stateLayer);
		this.map.addLayer(stateLinesLayer);
		this.map.addLayer(countryBoundaryLayer);
		this.map.addLayer(countryLayer);
		this.map.addLayer(pointLayer);
		//this.map.addLayer(distPointLayer);

		this.stLayer = stateLayer;
		this.stLinesLayer = stateLinesLayer;
		this.cntryLayer = countryLayer;
		this.pntLayer = pointLayer;
		this.distLayer = districtLayer;
		//this.distPntsLayer = distPointLayer;
		this.distLinesLayer = districtLinesLayer;
  }

  private ResetMapExtents(){
	var mapBounds = L.latLngBounds([ 6.5546079, 68.14712],[ 38.07842977751238, 98.41509800623419]);
	
	if(window.innerWidth<=1280){
		this.map.zoomLevel = 3;
	}
	else{
		this.map.zoomLevel = 4;
	} 

	this.map.setMaxBounds(mapBounds);
	this.autoZoom = true;
	this.map.fitBounds(mapBounds);
	this.autoZoom = false;
  }
  
  private initMap(): void {

	if(window.innerWidth<=1280){
		this.zoomLevel = 3;
	}
	else{
		this.zoomLevel = 4;
	}
	var zoomVal = this.zoomLevel;
	this.map = L.map('map', {
		center: [ 21.0000, 78.0000],
		zoom: zoomVal,
		minZoom: zoomVal,
		zoomSnap: 0.25,
		preferCanvas: true
	});
	
	this.ResetMapExtents();
	// here you want the reference to be info, therefore this = info
	// so do not use es6 to access the the class instance
	this.info.onAdd = function (map) {
	  this._div = L.DomUtil.create('div', 'info');
	  this.update();
	 return this._div;
	};

	L.control.browserPrint({
		title: 'Imprimer la carte',
		printModesNames: {
		  Portrait: 'Portrait',
		  Landscape: 'Paysage',
		  Auto: 'Auto',
		  Custom: 'Séléctionnez la zone'
		}
	  }).addTo(this.map);
	  
	this.map.on('zoomend', (e) => (this.onMapZoomEnd(e)));
	this.map.on('moveend', (e) => (this.onMapMoveEnd(e)));
	this.map.on('click', (e) => (this.onMapClick(e)));

	var stateChangingButton = L.easyButton({
		states: [{		
			icon:  'fa fa-times',
			title:  'Reset Map',
			onClick: (e) => (this.resetSelectLayer())
	    }]
    });
	 
	stateChangingButton.addTo(this.map);

	var homeButton = L.easyButton({
		states: [{		
			icon:  'fa fa-home',
			title:  'Zoom Extents',
			onClick: (e) => (this.ResetMapExtents())
	    }]
    });
	 
	homeButton.addTo(this.map);


	/* L.easyButton('<i class="material-icons"> cancel</i>',function(btn,map){	 
		map.setView([initialPos.lat, initialPos.lng], initialPos.zoom);
	  }).addTo(this.map); */

	//this.customImageAdd();
  }
  
  public resetSelectLayer() { 
		var layer = this.selLayer;
		if (layer){
			layer.setStyle({
				weight: 2,
				color: '#654321',
				fillColor: '#FFFFFF'
			});
		}
		layer = null;

		this.resetMap.emit(this.activeLevel);
		this.ResetMapExtents();
 }

 public resetSelectDistrictLayer() { 
	var layer = this.selDistrictLayer;
	if (layer){
		layer.setStyle({
			weight: 2,
			color: '#654321',
			fillColor: '#FFFFFF'
		});
	}
	layer = null;

	this.resetMap.emit(this.activeLevel);
	this.ResetMapExtents();
}


  private customImageAdd(){
	let statesLatLong:any=[[30.0668, 77.7064],[22.2587, 71.1924],[28.5355, 77.3910],[17.3850, 78.4867]];
	for(let i=1; i<statesLatLong.length; i++){
		var icon = L.divIcon({
			className: 'custom-div-icon',
			html: "<div  class='marker-pin'><img  style='width:50px' src='https://www.howtogeek.com/wp-content/uploads/2019/05/basic-pie-resized.png'>		</div>",
			/*iconSize: [30, 42],
			iconAnchor: [15, 42]*/
		});				
		var marker = L.marker(statesLatLong[i], {
				icon: icon
		}).addTo(this.map);
	}		       
  }

  onMapClick(event)
  {
	if (this.activeLevel !== "S" )
	{		
		return;
	}
  }

  onMapZoomEnd(event)
  {
	this.zoomLevel = event.target._zoom;
	if (! this.autoZoom)
	{
		//if (this.activeLevel === 'D')
		{
			this.redrawLabels();
		}
	}

}

onMapMoveEnd(event)
{
  if (! this.autoZoom)
  {
	this.redrawLabels();
  }

}

private highlightState(e)  { 
	const layer = e.target;
	/*
	if (this.activeLevel !== "S" && this.activeLevel !== "C" )
	{		
		return;
	}
	*/	
	// var state_ut = layer.feature.properties['NAME_1'];
	// var stateData = DataHelper.getStateData(this.activeSector, state_ut);
	// satyendra start
	// const layer = e.target;
	if (layer.feature.properties)
	{	
		var state_ut = layer.feature.properties['NAME_1'];
		var stateData = DataHelper.getStateData(this.activeSector, state_ut);
		//console.log(stateData);
		
		if (stateData){ 
			var out = '<div class="leafLetDetailsDiv">';   
			out += '<b> <label>'+ state_ut + '</label> </b>';
			out += "<table class='table customTbl text-left TFtable' >";
			out +='<tbody>';			
			if (this.activeSector)
			{
				var tempData= Array.from(stateData.keys());
				if(tempData.length>0){
					for (let key of tempData)
					{
						if(key!=='STATE_UT' && key!=='DistrictData' ){
							var dataval = stateData.get(key);	
							out +='<tr>';
							out +='<td>'+key+'</td>'+ '<td>'+(this.getDataVal(dataval))+'</td>';
							out +='</tr>';
						}
					}
				}
				else{
					out +='<tr>';
					out +="<td  class='warning'>No Data Available</td>";
					out +='</tr>';
				}				
			}
			out +='</tbody>';
			out +='</div>';
			var content = out;
			this.info.update = function (props) {
			   this._div.innerHTML = content;
			};
			this.info.addTo(this.map);
		}
		else{
			var out = '<div class="leafLetDetailsDiv">';   
			out += '<b style="text-align:center"> <label>'+ state_ut  + '</label> </b>';
			out += "<table class='table customTbl text-left TFtable' >";
			out +='<tbody>';
			out +='<tr>';
			out +="<td class='warning'>No Data Available</td></td>";
			out +='</tr>';
			out +='</tbody>';
			out +='</div>';
			var content = out;		

			this.info.update = function (props) {
			this._div.innerHTML = content;
			};
			this.info.addTo(this.map);
		}

	}

	// satyendra end

	/*
	if(!stateData)
	{		 
		   		
			var out = '<div class="leafLetDetailsDiv">';   
			out += '<b style="text-align:center"> <label>'+ state_ut  + '</label> </b>';
			out += "<table class='table customTbl text-left TFtable' >";
			out +='<tbody>';
			out +='<tr>';
			out +="<td class='warning'>No Data Available</td>'+'</td>";
			out +='</tr>';
			out +='</tbody>';
			out +='</div>';
			var content = out;		

			this.info.update = function (props) {
			this._div.innerHTML = content;
			};
			this.info.addTo(this.map);
	}
	else{
		if (this.activeLevel === "D"){
			var out = '<div class="leafLetDetailsDiv">';   
			out += '<b style="text-align:center"> <label>'+ state_ut  + '</label> </b>';
			out += "<table class='table customTbl text-left TFtable' >";
			out +='<tbody>';
			out +='<tr>';
			out +="<td class='warning'>No Data Available</td>"+'</td>';
			out +='</tr>';
			out +='</tbody>';
			out +='</div>';
			var content = out;		

			this.info.update = function (props) {
			this._div.innerHTML = content;
			};
			this.info.addTo(this.map);
		}
	}
	
	*/

  }

  private resetState(e)  {
    const layer = e.target;	
	this.map.removeControl(this.info);
	//if(layer == this.selLayer)
	//this.setSelectedLayerStyle();
  }
  private getDataVal(data){
	if (!data || data == "undefined")			
		return '-';
	else
		return data;
  }

  private highlightDistrict(e)  {  
	if (this.activeLevel !== "D" )
	{		
		return;
	}
    const layer = e.target;
	if (layer.feature.properties)
	{			
		var state_ut = layer.feature.properties['NAME_1'];
		var district = layer.feature.properties['NAME_2'];		
		var DistrictData = DataHelper.getDistrictDisplayProperties(this.activeSector, state_ut, district);
		
		if (DistrictData){ 
			var out = '<div class="leafLetDetailsDiv">';   
			out += '<b> <label>'+ district + ' (' + state_ut + ')' + '</label> </b>';
			out += "<table class='table customTbl text-left TFtable' >";
			out +='<tbody>';			
			if (this.activeSector)
			{
				var tempData= Array.from(DistrictData.keys());
				if(tempData.length>0){
					// if (this.activeSector == 'Combined')
					// {
					// 	dataval = "-"; 
					// 	out +='<tr>';
					// 		out +='<td>' + "Sector" + '<td/>'+ '<td>'+"WASTE GENERATION<br>(000 TONS)"+'</td>' +
					// 		'<td>'+'ENERGY_POTENTIAL<br>(MW)'+'</td>' + '<td>'+'BIO-CNG POTENTIAL<br>(TONS/DAY)'+'</td>';
					// 	out +='</tr>';

					// 	out +='<tr>';
					// 		out +='<td>' + "Cattle" + '<td/>'+ '<td>'+(this.getDataVal(DistrictData.get('Cattle_Waste_Generation')))+'</td>' +
					// 		'<td>'+(this.getDataVal(DistrictData.get('Cattle_Energy_Potential')))+'</td>' + '<td>'+(this.getDataVal(DistrictData.get('Cattle_Bio_CNG')))+'</td>';
					// 	out +='</tr>';

					// 	out +='<tr>';
					// 		out +='<td>' + "Poultry" + '<td/>'+ '<td>'+(this.getDataVal(DistrictData.get('Poultry_Waste_Generation')))+'</td>' +
					// 		'<td>'+(this.getDataVal(DistrictData.get('Poultry_Energy_Potential')))+'</td>' + '<td>'+(this.getDataVal(DistrictData.get('Poultry_Bio_CNG')))+'</td>';
					// 	out +='</tr>';

					// 	out +='<tr>';
					// 		out +='<td>' + "PressMud" + '<td/>'+ '<td>'+(this.getDataVal(DistrictData.get('SF_Waste_Generation')))+'</td>' +
					// 		'<td>'+(this.getDataVal(DistrictData.get('SF_Energy_Potential')))+'</td>' + '<td>'+(this.getDataVal(DistrictData.get('SF_Bio_CNG')))+'</td>';
					// 	out +='</tr>';

					// 	out +='<tr>';
					// 		out +='<td>' + "Total" + '<td/>'+ '<td>'+(this.getDataVal(DistrictData.get('Total_Waste_Generation')))+'</td>' +
					// 		'<td>'+(this.getDataVal(DistrictData.get('Total_Energy_Potential')))+'</td>' + '<td>'+(this.getDataVal(DistrictData.get('Total_Bio_CNG')))+'</td>';
					// 	out +='</tr>';


					// }
					// else
					{
						for (let key of tempData)
						{
							var dataval = DistrictData.get(key);	
			
							out +='<tr>';
							out +='<td>'+key+'</td>'+ '<td>'+(this.getDataVal(dataval))+'</td>';
							out +='</tr>';
						}
					}
				}
				else{
					out +='<tr>';
					out +="<td  class='warning'>No Data Available</td>";
					out +='</tr>';
				}				
			}
			out +='</tbody>';
			out +='</div>';
			var content = out;
			this.info.update = function (props) {
			   this._div.innerHTML = content;
			};
			this.info.addTo(this.map);
		}
	}
  }

  private clickState(e){ 
	const layer = e.target;
	//console.log(layer);
	if (this.activeLevel !== "S" && this.activeLevel !== "C" )
	{		
		return;
	}	
	var state_ut = layer.feature.properties['NAME_1'];
	if (state_ut === this.activeState)
		return;
	var stateData = DataHelper.getStateData(this.activeSector, state_ut);
	var curColor = DataHelper.getSectorColor(this.activeSector); //sscolor
	//console.log(curColor);
	if(stateData)
	{
		
		this.activeState = state_ut;
		this.activeDistrict = '';
		this.resetDistrictLayerStyle(this.selDistrictLayer);
		// this.resetLayerStyle(this.selLayer);
		this.resetLayerStyless(this.selLayer, curColor);
		this.selLayer = layer;
		this.selDistrictLayer = null;
		
		//this.zoomToActiveState();
		// this.setSelectedLayerStyle(false);
		this.setSelectedLayerStyle(false);
		this.mapSelectionChanged.emit([stateData]);

	}
	
  }

  private clickDistrict(e){  
	const layer = e.target;
	if (this.activeLevel !== "D")
	{		
		return;
	}
	var state_ut = layer.feature.properties['NAME_1'];
	var district = layer.feature.properties['NAME_2'];
	if (state_ut === this.activeState && district == this.activeDistrict)
		return;
	var stateData = DataHelper.getStateData(this.activeSector, state_ut);

	if(stateData)
	{
		var districtData = DataHelper.getDistrictData(this.activeSector, state_ut);
		if (districtData && districtData.has(district))
		{
			if (this.selDistrictLayer){
				this.resetDistrictLayerStyle(this.selDistrictLayer, this.activeSector);
			}
		    if (this.selLayer){
				this.resetLayerStyle(this.selLayer);
			}
			
			if (this.activeState !== state_ut)
			{	
				this.activeState = state_ut;
				for(let index in this.stLayer._layers) {
					var lyr = this.stLayer._layers[index];
					if (lyr.feature.properties['NAME_1'] === state_ut)
					{
						this.selLayer = lyr;
						//this.zoomToActiveState();
						break;
					}
				}	
				
			}

			for(let index in this.distLayer._layers) {
				var lyr = this.distLayer._layers[index];
				if (lyr.feature.properties['NAME_1'] === state_ut && lyr.feature.properties['NAME_2'] === district)
				{
					this.selDistrictLayer = lyr;
					break;
				}
			}		
			
			this.activeDistrict = district;

			this.mapSelectionChanged.emit([stateData, district]);
			
			this.setSelectedDistrictLayerStyle();
			this.setSelectedLayerStyle(true);
		}
			
	}
  }

  public checkDistrictLevelData(layer){
	var state_ut = layer.feature.properties['NAME_1'];
	var district = layer.feature.properties['NAME_2'];	
	var DistrictData = DataHelper.getDistrictDisplayProperties(this.activeSector, state_ut, district);
	if (DistrictData){ 
		var tempData= Array.from(DistrictData.keys());
		if(tempData.length===0){
           return false;
		}
	}
	return true;
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
	
	for (let propName in changes) {
		if (propName == null)
			continue;
		let changedProp = changes[propName];
		if (changedProp.currentValue == null)
			continue;
		if (propName === "activeSector")
		{
			
			if (this.activeLevel === "D")
			{
				this.removeStates();
				if (this.distLayer){					
					this.map.addLayer(this.distLayer);
					this.redrawDistricts();
				}
			}

			if (this.activeLevel === "S")
			{
				if (this.stLayer){					
					this.map.addLayer(this.stLayer);
					this.redrawStates();
				}
			}

			
		}
		else if (propName === "activeLevel" && this.map)
		{
			this.activeState = "";
			this.activeDistrict = "";
			if (this.selDistrictLayer)
				this.resetDistrictLayerStyle(this.selDistrictLayer);
			if (this.selLayer)
				this.resetLayerStyle(this.selLayer);
			if (this.activeLevel === "D")
			{				
				if (this.distLayer){					
					this.map.addLayer(this.distLayer);
					this.redrawDistricts();
				}
			}
			else{
				this.removeDistricts();
			}

			//ss
			if (this.activeLevel === "S")
			{	
				if (this.stLayer){					
					this.map.addLayer(this.stLayer);
					this.redrawStates();
				}
			}
			else{
				this.redrawStates();
			}
			//

		}
	  }
  }

  removeStates()
  {
	if (this.stLayer){
		this.map.removeLayer(this.stLayer);
	}
	if (this.stLinesLayer){
		this.map.removeLayer(this.stLinesLayer);
	}
	if (this.pntLayer){
		this.map.removeLayer(this.pntLayer);
	}
  }

  removeDistricts()
  {
	if (this.distLayer){
		this.map.removeLayer(this.distLayer);
	}
	if (this.distLinesLayer){
		this.map.removeLayer(this.distLinesLayer);
	}
	if (this.distPntsLayer){
		this.map.removeLayer(this.distPntsLayer);
		this.distPntsLayer = null;
	}
  }
  
  zoomToActiveState()
  {
	if (this.selLayer && this.activeState !== '')
	{
		var stateBounds = this.selLayer.getBounds();
		this.autoZoom = true;
		this.map.fitBounds(stateBounds);	
		this.autoZoom = false;
	}
  }


//   sss for state color
redrawStates(){
	var sector = this.activeSector;
	var fColor = DataHelper.getSectorColor(sector);
	this.stLayer.eachLayer(function(layer) {

		//var containsDistrictData = false;
		var containsStateData = false;

		var stateName = layer.feature.properties["NAME_1"];
		
		var districtName = layer.feature.properties["NAME_2"];
		// var stateData = DataHelper.getStateData(sector, stateName);
		var stateData = DataHelper.getStateDataSSS(sector, stateName);
		
		// console.log(stateData);
		if (stateData){
			containsStateData = stateName ? true : false; //stateData.get(stateName.toUpperCase()) ? true : false;
			
		}
			layer.setStyle({
				weight: 1,
				color: '#013220',
				opacity: containsStateData ? .2 : 0,
				fillColor:fColor,
				fillOpacity:containsStateData ? .5 : 0,						
			});

	});

	if (this.stLayer){
		this.map.addLayer(this.stLayer);
	}
	this.redrawLabels();
	
  }
// sss for state color end



  redrawDistricts(){
	var sector = this.activeSector;
	var fColor = DataHelper.getSectorColor(sector);
	this.distLayer.eachLayer(function(layer) {

		var containsDistrictData = false;

		var stateName = layer.feature.properties["NAME_1"];


		var districtName = layer.feature.properties["NAME_2"];
		var districtData = DataHelper.getDistrictData(sector, stateName);

		// console.log(districtData);
		if (districtData){
			containsDistrictData = districtData.get(districtName.toUpperCase()) ? true : false;
		}
			layer.setStyle({
				weight: 1,
				color: '#013220',
				opacity: containsDistrictData ? .2 : 0,
				fillColor:fColor,
				fillOpacity:containsDistrictData ? .5 : 0,						
			});

	});

	if (this.distLinesLayer){
		this.map.addLayer(this.distLinesLayer);
	}
	this.redrawLabels();
  }

  redrawLabels()
  {	  
	var distLabelClass = 'class-labels_1';
	var stateLabelClass = 'class-labels_3';

	if (!this.zoomLevel || this.zoomLevel <= 6){
		distLabelClass = 'class-labels_1';
		stateLabelClass = 'class-labels_3'
	}
	else if (this.zoomLevel <= 8)
	{
		distLabelClass = 'class-labels_2';
		stateLabelClass = 'class-labels_4'
	}
	else if (this.zoomLevel <= 10)
	{
		distLabelClass = 'class-labels_3';
		stateLabelClass = 'class-labels_5'
	}
	else
	{
		distLabelClass = 'class-labels_4';
		stateLabelClass = 'class-labels_5'
	}
	
	if (this.pntLayer){
		this.map.removeLayer(this.pntLayer);
	}
	var stateTooltipClass = {
		'className': stateLabelClass
	}
	var sector = this.activeSector;
	var stPointLayer = L.geoJSON(null, {
		pointToLayer: function(feature,latlng){
			var stateName = feature.properties["ST_NAME"];
			return new L.CircleMarker(latlng, 
									{radius: 0.1,
									opacity: 0.01
									}).bindTooltip(stateName, 
												{permanent: true, 
												direction: "center",												
												...stateTooltipClass},
												stateTooltipClass);
			}
	});

	var stPoints = 'assets/INDIA_STATE_PT.zip';
        shp(stPoints).then(function(data){
			stPointLayer.addData(data);
		});

	this.map.addLayer(stPointLayer);
	this.pntLayer = stPointLayer;

	if (this.activeLevel === 'D' && 
			((distLabelClass !== this.prevDistLabelClass) ||
			 (distLabelClass !== "class-labels_1")))
	{

		this.prevDistLabelClass = distLabelClass;
		if (this.distPntsLayer){
			this.map.removeLayer(this.distPntsLayer);
			this.distPntsLayer = null;
		}
		var distTooltipClass = {
			'className': distLabelClass
		}
		var sector = this.activeSector;
		var map = this.map;
		var distPointLayer = L.geoJSON(null, {
			pointToLayer: function(feature,latlng){
				var stateName = feature.properties["NAME_1"];
				var distName = feature.properties["NAME_2"];
				
				if (! map.getBounds().contains(latlng))
					return null;

				// var districtData = DataHelper.getDistrictData(sector, stateName) ? true : false;

				// if (! districtData)
				// 	return null;

				var label = distName;

				
				return new L.CircleMarker(latlng, 
										{radius: 0.1,
										opacity: 0.01
										}).bindTooltip(label, 
													{permanent: true, 
													direction: "center",
													...distTooltipClass},
													distTooltipClass);
				}
		});

		var distPoints = 'assets/INDIA_DISTRICT_PT.zip';
			shp(distPoints).then(function(data){
				distPointLayer.addData(data);
			});

		this.map.addLayer(distPointLayer);
		this.distPntsLayer = distPointLayer;
		}
		
  }

  addStates(){
	if (this.stLayer){
		this.map.addLayer(this.stLayer);
	}
	if (this.stLinesLayer){
		this.map.addLayer(this.stLinesLayer);
	}
	if (this.pntLayer){
		this.map.addLayer(this.pntLayer);
	}

  }

  roundToTwo(num): Number {    
	return Math.round( num * 100 + Number.EPSILON ) / 100;
	}

  setSelectedLayerStyle(onlyBorder)
  {
	  if (this.selLayer){
		this.selLayer.setStyle({
		weight: 4,
		color: '#0000FF',
		fillColor: onlyBorder ? '#FFFFFF' : '#14B1E5'
		});
	}
  }

  setSelectedDistrictLayerStyle()
  {
	  if (this.selDistrictLayer){
		this.selDistrictLayer.setStyle({
		weight: 3,
		color: '#0000FF',
		fillOpacity:1
		});
	}
  }

  resetLayerStyle(layer)
  {	
	  if (layer){
		layer.setStyle({
			weight: 2,
			color: '#654321',
			fillColor: '#FFFFFF'
		});
	  }
	  layer = null;
  }
  
  
  resetLayerStyless(layer, fiColor)
  {	
	  if (layer){
		layer.setStyle({
			weight: 2,
			color: '#654321',
			fillColor: fiColor
		});
	  }
	  layer = null;
  }

  resetDistrictLayerStyle(layer, sector = null)
  {	
	var fillColorCode = '#FFFFFF';  
	var districtData = DataHelper.getDistrictData(sector, this.activeState);
	var stateData = DataHelper.getStateData(sector, this.activeState);

	if (districtData)
	{
		if (districtData.has(this.activeDistrict))
			fillColorCode = DataHelper.getSectorColor(sector);
	}

	if (stateData)
	{
		if (stateData.has(this.activeState))
			fillColorCode = DataHelper.getSectorColor(sector);
	}

	  if (layer){
		layer.setStyle({
			weight: 1,
			color: '#013220',
			fillColor: fillColorCode,
			fillOpacity: 0.5
		});
	  }

	  layer = null;
  } 

  
  getFillColor(feature) : string{	  

		return feature.properties["COLOR_CODE"];
  }


}
